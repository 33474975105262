import { useContext } from 'react';

import { en } from '../i18n/en';

import {
  MessageContext,
  MessageContextType,
  Agree,
  SetMessage,
} from '@contexts';

export type ActionResultReturn = {
  successMsg: () => SetMessage;
  failMsg: () => SetMessage;
};

type MessageFns = Record<
  string,
  ActionResult | MessageTypeA | MessageTypeB | MessageTypeC
>;

const SEVERITY_ERROR = 'error';

const SEVERITY_WARNING = 'warning';

const SEVERITY_INFO = 'info';

const SEVERITY_SUCCESS = 'success';

export type MessageTypeA = (n: string, fn: Agree) => SetMessage;

export type MessageTypeB = (fn: Agree) => void;

export type MessageTypeC = () => SetMessage;

export type MessageTypeD = (fn: Agree) => () => void;

export type ActionResult = (name: string) => ActionResultReturn;

export const INVALID_FIELDS = 'INVALID_FIELDS';

export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';

export const CUSTOMER_DELETE_VERIFY = 'CUSTOMER_DELETE_VERIFY';

export const CUSTOMER_PUT = 'CUSTOMER_PUT';

export const CUSTOMER_POST = 'CUSTOMER_POST';

export const CUSTOMER_ADMIN_POST = 'CUSTOMER_ADMIN_POST';

export const USER_GET_FAIL = 'USER_GET_FAIL';

export const USER_DELETE = 'USER_DELETE';

export const USER_DELETE_VERIFY = 'USER_DELETE_VERIFY';

export const USER_PUT = 'USER_PUT';

export const USER_POST = 'USER_POST';

export const DATAPOINTS_RESET_VERIFY = 'DATAPOINTS_RESET_VERIFY';

export const DATAPOINTS_VERIFY = 'DATAPOINTS_VERIFY';

export const DATAPOINTS_GET_FAIL = 'DATAPOINTS_GET_FAIL';

export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';

export const NO_SELECTED_CUSTOMER = 'NO_SELECTED_CUSTOMER';

const noAction = () => {};

const action = (title: string) => (actionType: string) => (name: string) => ({
  isOpen: true,
  title: en.translation.Messages.Action.Title(title),
  body: en.translation.Messages.Action.Body(name, actionType),
  severity: actionType.startsWith('not') ? SEVERITY_ERROR : SEVERITY_SUCCESS,
  onAgree: noAction,
});

const fail = action(en.translation.Messages.Fail);

const success = action(en.translation.Messages.Success);

const failPost = fail(en.translation.Messages.NotCreated);

const successPost = success(en.translation.Messages.Created);

const failDelete = fail(en.translation.Messages.NotDeleted);

const successDelete = success(en.translation.Messages.Deleted);

const failPut = fail(en.translation.Messages.NotUpdated);

const successPut = success(en.translation.Messages.Updated);

const messageTypes = new Map()
  .set(CUSTOMER_PUT, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successPut(name)),
    failMsg: () => setMessage(failPut(name)),
  }))
  .set(CUSTOMER_DELETE, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successDelete(name)),
    failMsg: () => setMessage(failDelete(name)),
  }))
  .set(
    CUSTOMER_DELETE_VERIFY,
    (setMessage: SetMessage) => (name: string, onAgree: Agree) =>
      setMessage({
        isOpen: true,
        title: en.translation.Messages.CustomerDeleteVerify.Title,
        body: en.translation.Messages.CustomerDeleteVerify.Body(name),
        severity: SEVERITY_INFO,
        onAgree,
        onDisagree: true,
      })
  )
  .set(CUSTOMER_POST, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successPost(name)),
    failMsg: () => setMessage(failPost(name)),
  }))
  .set(CUSTOMER_ADMIN_POST, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successPost(name)),
    failMsg: () => setMessage(failPost(name)),
  }))
  .set(USER_POST, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successPost(name)),
    failMsg: () => setMessage(failPost(name)),
  }))
  .set(USER_PUT, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successPut(name)),
    failMsg: () => setMessage(failPut(name)),
  }))
  .set(USER_DELETE, (setMessage: SetMessage) => (name: string) => ({
    successMsg: () => setMessage(successDelete(name)),
    failMsg: () => setMessage(failDelete(name)),
  }))
  .set(
    USER_DELETE_VERIFY,
    (setMessage: SetMessage) => (name: string, onAgree: Agree) =>
      setMessage({
        isOpen: true,
        title: en.translation.Messages.UserDeleteVerify.Title,
        body: en.translation.Messages.UserDeleteVerify.Body(name),
        severity: SEVERITY_INFO,
        onAgree,
        onDisagree: true,
      })
  )
  .set(
    DATAPOINTS_GET_FAIL,
    (setMessage: SetMessage) => () =>
      setMessage({
        title: en.translation.Messages.DataPointsGetFail.Title,
        body: en.translation.Messages.DataPointsGetFail.Body,
        severity: SEVERITY_ERROR,
        isOpen: true,
        onAgree: noAction,
      })
  )
  .set(
    DATAPOINTS_RESET_VERIFY,
    (setMessage: SetMessage) => (onAgree: Agree) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_INFO,
        title: en.translation.Messages.DataPointsResetVerify.Title,
        body: en.translation.Messages.DataPointsResetVerify.Body,
        onAgree,
        onDisagree: true,
      })
  )
  .set(
    DATAPOINTS_VERIFY,
    (setMessage: SetMessage) => (onAgree: Agree) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_INFO,
        title: en.translation.Messages.DataPointsVerify.Title,
        body: en.translation.Messages.DataPointsVerify.Body,
        onAgree,
        onDisagree: true,
      })
  )
  .set(
    INVALID_FIELDS,
    (setMessage: SetMessage) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_WARNING,
        title: en.translation.Messages.InvalidFields.Title,
        body: en.translation.Messages.InvalidFields.Body,
        onAgree: noAction,
      })
  )
  .set(
    USER_GET_FAIL,
    (setMessage: SetMessage) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_ERROR,
        title: en.translation.Messages.UnableToFetchUser.Title,
        body: en.translation.Messages.UnableToFetchUser.Body,
        onAgree: noAction,
      })
  )
  .set(
    NO_SELECTED_CUSTOMER,
    (setMessage: SetMessage) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_WARNING,
        title: en.translation.Messages.NoSelectedCustomer.Title,
        body: en.translation.Messages.NoSelectedCustomer.Body,
        onAgree: noAction,
      })
  )
  .set(
    SOMETHING_WENT_WRONG,
    (setMessage: SetMessage) => () =>
      setMessage({
        isOpen: true,
        severity: SEVERITY_ERROR,
        title: en.translation.Messages.SomethingWentWrong.Title,
        body: en.translation.Messages.SomethingWentWrong.Body,
        onAgree: noAction,
      })
  );

const toLowerCase = (s: string) => s.toLowerCase();

const upperFirstCase = (s: string) =>
  `${s.charAt(0).toUpperCase()}${s.slice(1)}`;

function makeFnName(m: string) {
  return `do${m.split('_').map(toLowerCase).map(upperFirstCase).join('')}`;
}

function getMessages(setMessage: SetMessage, messages: string[]) {
  return messages.reduce(
    (acc, m) =>
      messageTypes.has(m)
        ? {
            ...acc,
            [makeFnName(m)]: messageTypes.get(m)(setMessage),
          }
        : acc,
    {} as MessageFns
  );
}

export function useMessage(...messages: string[]) {
  const { setMessage } = useContext(MessageContext) as MessageContextType;

  return getMessages(setMessage, messages);
}
