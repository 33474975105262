import { LoginScreen } from '@views';
import { Navigate, Route, Routes } from 'react-router-dom';

export default function InitUnProtectedApp() {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
