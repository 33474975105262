export type Validator = Record<
  string,
  {
    validate: (v: string | null | boolean) => boolean;
    error: string;
  }
>;

export type ValidatorPredicate = (v: ValidType) => boolean;

export type ValidType = string | boolean | null;

interface ValidatorCheck {
  [k: string]: ValidType | ValidatorCheck;
}

export function isAllValid(validators: Validator) {
  return function (obj: ValidatorCheck): boolean {
    return !Object.entries(validators).every(([k, { validate }]) => {
      if (typeof obj[k] === 'object') {
        return true; // recurse! fix!
      }
      if (typeof obj[k] === 'boolean') {
        return true;
      }
      if (k in obj) {
        return validate(obj[k] as ValidType);
      }
      return false;
    });
  };
}

export function combineValidators(...fns: ValidatorPredicate[]) {
  return function (x: ValidType) {
    return fns.every(applyValidator(x));
  };
}

export const applyValidator = (x: ValidType) => (fn: ValidatorPredicate) =>
  fn(x);

export const isInt = (v: ValidType) => Number.isInteger(parseInt(v as string));

export const isString = (v: ValidType): v is string => typeof v === 'string';

export const isLength = (len: number) => (v: ValidType) =>
  (v as string).length === len;

export const isLengthGreaterThan = (len: number) => (v: ValidType) =>
  (v as string).length > len;

export const isIncludeOnly = (regExp: RegExp) => (v: ValidType) =>
  regExp.test(v as string);

// regular expression from:
// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
export const isEmail = combineValidators(
  isString,
  isLengthGreaterThan(5),
  isIncludeOnly(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
);

// check regular from auth0
export const isValidPassword = combineValidators(
  isString,
  isLengthGreaterThan(8)
);

export const isPersonalId = combineValidators(isString, isLength(12), isInt);

export const isName = combineValidators(isString, isLengthGreaterThan(1));

export const isOrganizationId = combineValidators(
  isString,
  isInt,
  isLengthGreaterThan(6)
);

export const isPhone = combineValidators(
  isString,
  isInt,
  isLengthGreaterThan(6)
);

export const isZipCode = combineValidators(
  isString,
  isInt,
  isLengthGreaterThan(6)
);

export const isCity = combineValidators(isString, isLengthGreaterThan(3));

export const isCountry = combineValidators(isString, isLengthGreaterThan(3));

export const isAddress = combineValidators(isString, isLengthGreaterThan(3));

export const isIntegerGreaterThan = (x: number) => (v: ValidType) =>
  parseInt(v as string, 10) > x;

export const isIntegerLesserThan = (x: number) => (v: ValidType) =>
  parseInt(v as string, 10) < x;

export const isAge = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(17)
);

export const isHeight = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(50),
  isIntegerLesserThan(300)
);

export const isWeight = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(40),
  isIntegerLesserThan(300)
);

export const isFG_ngDL = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(0),
  isIntegerLesserThan(1000)
);

export const isFST_ng_mL = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(0),
  isIntegerLesserThan(50000)
);
export const isC_pep_ng_mL = isFG_ngDL;

export const isHba1Baseline = combineValidators(
  isString,
  isInt,
  isIntegerGreaterThan(0),
  isIntegerLesserThan(100)
);
