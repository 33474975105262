import GaugeChart from 'react-gauge-chart';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

import { RiskPredictionResult } from '@views/RiskPrediction';
import DashboardPaper from './DashboardPaper';

type Props = {
  riskPredictionResult: RiskPredictionResult;
};
export default function RiskPredictionResultVisualisation({
  riskPredictionResult,
}: Props) {
  const { t } = useTranslation('translation', { i18n });

  return (
    <DashboardPaper>
      <Typography
        variant="h3"
        component="h3"
        sx={{ marginLeft: '10px', marginBottom: '20px' }}
      >
        LD<em>coder</em>
      </Typography>
      <GaugeChart
        id="gauge-chart6"
        animate={true}
        nrOfLevels={5}
        percent={riskPredictionResult.value}
        hideText={true}
        needleColor="#345243"
      />
      <Box>
        <Typography
          variant="h5"
          component="h3"
          sx={{ textAlign: 'center', marginTop: '10px' }}
        >
          {t('RiskPredictionResultVisualisation.ResultText')}:{' '}
          <strong>{riskPredictionResult.result}</strong>
        </Typography>
      </Box>
    </DashboardPaper>
  );
}
