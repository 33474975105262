import {
  FORM_RESET,
  FORM_UPDATE,
  DispatchReset,
  DispatchUpdate,
  isDispatchUpdate,
} from '@utils/helpers-reducers';

const formInit = () => {
  let vs: any = null;
  return {
    set: (initialValues: Record<string, unknown>) => {
      if (!vs) {
        vs = initialValues;
      }
    },
    isEmpty: () => vs === null,
    getInitialValues: () => {
      return vs;
    },
  };
};

const form = formInit();

export function formReducer<T>(
  vs: Record<string, T>,
  action: DispatchUpdate<T> | DispatchReset
) {
  if (form.isEmpty()) {
    form.set(vs);
  }
  if (action.type === FORM_RESET) {
    return form.getInitialValues();
  }
  if (!isDispatchUpdate(action)) {
    throw new Error('Invalid action.');
  }
  if (!action.payload.key || !(action.payload.key in vs)) {
    throw new Error('Invalid key.');
  }
  if (action.type === FORM_UPDATE) {
    return {
      ...vs,
      [action.payload.key]: action.payload.value,
    };
  }
  return vs;
}
