import { FocusEvent, ChangeEvent, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n/i18n';
type Props = {
  label: string;
  value: string;
  unit?: string;
  update: (v: string) => void;
  validator: {
    validate: (v: string) => boolean;
    error: string;
  };
  disabled?: boolean;
  type?: string;
  sx?: { m?: number; width?: string; marginLeft?: string };
};
export default function InputField({
  label,
  value,
  unit,
  update,
  validator,
  disabled,
  type,
  sx,
}: Props) {
  const { t } = useTranslation('translation', { i18n });
  const [error, setError] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    update(e.target.value as string);

  const onBlur = (e: FocusEvent<HTMLInputElement>) =>
    !validator.validate(e.target.value)
      ? setError(t(validator.error))
      : setError('');

  return (
    <FormControl>
      <TextField
        label={label}
        error={Boolean(error)}
        helperText={error}
        type={type ?? 'text'}
        value={value}
        disabled={disabled ?? false}
        autoComplete="false"
        onChange={onChange}
        onBlur={onBlur}
        id="outlined-start-adornment"
        size="small"
        sx={sx ?? { m: 1, width: '25ch' }}
        InputProps={{
          endAdornment: unit && (
            <InputAdornment position="start">{unit}</InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
