import { useState, useEffect, useRef } from 'react';

const FIVE_MINUTES = 10000000000000; //1000 * 60 * 5;

export default function useIsUserActive() {
  const [isActive, setIsActive] = useState(true);
  const activityCount = useRef(0);

  const incActivityCount = () => {
    activityCount.current++;
  };

  useEffect(() => {
    document.addEventListener('keydown', incActivityCount);
    return () => document.removeEventListener('keydown', incActivityCount);
  });

  useEffect(() => {
    document.addEventListener('mousemove', incActivityCount);
    return () => document.removeEventListener('mousemove', incActivityCount);
  });

  useEffect(() => {
    const isUserActiveLast5Minutes = setInterval(() => {
      if (activityCount.current === 0) {
        setIsActive(false);
      }
      activityCount.current = 0;
    }, FIVE_MINUTES);
    return () => clearInterval(isUserActiveLast5Minutes);
  });

  return isActive;
}
