import { Typography } from '@mui/material';

type Props = {
  text: string;
  pathName?: string;
};
export default function MenuItemLink({ pathName, text }: Props) {
  const { pathname } = window.location;

  return (
    <Typography
      sx={{ color: pathname === pathName ? '#90caf9' : '#fff' }}
      textAlign="center"
    >
      {text}
    </Typography>
  );
}
