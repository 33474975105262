import { useState } from 'react';

export type TableItem = Record<string, unknown>;

type Table = {
  rows: TableItem[];
  total: number;
  page: number;
  perPage: number;
};
export default function useTable() {
  const [table, setTable] = useState<Table>({
    rows: [],
    total: 0,
    page: 0,
    perPage: 10,
  });
  const { rows, total, page, perPage } = table;

  const updateTable = (updatedTable: Partial<Table>) => {
    setTable({ ...table, ...updatedTable });
  };

  return {
    rows,
    total,
    page,
    perPage,
    updateTable,
  };
}
