import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import UserIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { Loading } from "@components";
import { AccessTokenContext, AccessTokenContextType } from "@contexts";
import {
  ActionResult,
  useMessage,
  MessageTypeA,
  USER_DELETE_VERIFY,
  USER_DELETE,
} from "@hooks";
import { deleteUser } from "@utils/helpers-ldcoder-api";
import { User } from "@typings";

export default function AdminUpdateUser() {
  const { t } = useTranslation("translation", { i18n });
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const navigate = useNavigate();
  const location = useLocation();
  const { doUserDelete, doUserDeleteVerify } = useMessage(
    USER_DELETE,
    USER_DELETE_VERIFY
  );
  const [isLoading, setIsLoading] = useState(false);
  const user = location.state as User;
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);

  const _deleteUser = async () => {
    setIsLoading(true);
    const isSuccess = await deleteUser(
      accessToken,
      user,
      setIsDeleteDisabled,
      doUserDelete as ActionResult
    );
    if (isSuccess) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const onClickDeleteUser = () =>
    (doUserDeleteVerify as MessageTypeA)(
      `${user.name} ${user.family_name}`,
      _deleteUser
    );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container alignItems="center" justifyContent="center">
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  align: "center",
                  marginBottom: "20px",
                }}
              >
                <UserIcon sx={{ fontSize: "80px" }} />
              </Avatar>
            </Grid>
            <Box sx={{ width: "50ch" }}>
              <Typography variant="h4" sx={{ margin: "30px 12px" }}>
                {`${user.name} ${user.family_name ?? ""}`}
              </Typography>
              <TextField
                label={t("UpdateUser.Info.Role")}
                value={user.role}
                disabled={true}
                size="small"
                sx={{ m: 1, width: "25ch" }}
              />
              <TextField
                label={t("UpdateUser.Info.Email")}
                value={user.email}
                disabled={true}
                size="small"
                sx={{ m: 1, width: "25ch" }}
              />
              {user.last_ip && (
                <>
                  <TextField
                    label={t("UpdateUser.Info.LastIP")}
                    value={user.last_ip}
                    disabled={true}
                    size="small"
                    sx={{ m: 1, width: "25ch" }}
                  />
                </>
              )}
              <Divider />
              <Button
                sx={{ margin: "15px" }}
                variant="outlined"
                disabled={isDeleteDisabled}
                onClick={onClickDeleteUser}
              >
                {t("UpdateUser.Button.DeleteUser")}
              </Button>
              <Button
                sx={{ margin: "15px" }}
                variant="outlined"
                onClick={() => navigate("/")}
              >
                {t("UpdateUser.Button.BackToUsers")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
