import crossFetch from 'cross-fetch';
import fetchRetry from 'fetch-retry';

const fetchWithRetry = fetchRetry(crossFetch, {
  retries: 10,
  retryDelay: 500,
});

export type HTTP_METHOD = 'POST' | 'PUT' | 'DELETE' | 'GET' | 'PATCH';

export default function request(method: HTTP_METHOD, url: string) {
  return function _headers(headers?: Record<string, unknown>) {
    return async function _body(body?: Record<string, unknown>) {
      try {
        const options: RequestInit = { method };
        options.mode = 'cors';
        if (headers) {
          options.headers = headers as HeadersInit;
        }
        if (body) {
          options.body = JSON.stringify(body);
        }
        const response = await fetchWithRetry(url, options);
        try {
          const result = await response.json();
          return result;
        } catch (e) {
          return response;
        }
      } catch (e) {
        throw e;
      }
    };
  };
}
