import {
  AccessTokenContext,
  AccessTokenContextType,
  UserContext,
  UserContextType,
} from '@contexts';
import { routes } from '@routes';

type Props = UserContextType & AccessTokenContextType;

export default function InitProtectedApp({
  accessToken,
  setAccessToken,
  user,
  setUser,
}: Props) {
  return (
    <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
      <UserContext.Provider value={{ user, setUser }}>
        {routes.get(user.user_metadata.role)}
      </UserContext.Provider>
    </AccessTokenContext.Provider>
  );
}
