import { ActionResult, MessageTypeC } from '@hooks';
import { NewUser, NewCustomerAdmin, User } from '@typings';
import {
  FORM_RESET,
  DispatchUpdate,
  DispatchReset,
} from '@utils/helpers-reducers';
import {
  deleteLDCoderCustomer,
  postLDCoderCustomerAdmin,
  postLDCoderCustomer,
  putLDCoderCustomer,
  deleteLDCoderUser,
  putLDCoderUser,
  postLDCoderUser,
  getLDCoderRiskPrediction,
} from './ldcoder-api';
import { _newCustomer } from '@components/SuperadminCreateCustomer';
import { _Account } from '@views/Account';
import { _DataPoints, RiskPredictionResult } from '@views/RiskPrediction';

const fmtCustomerPut = (newCustomer: typeof _newCustomer) => ({
  name: newCustomer.name,
  country_organization_id: newCustomer.country_organization_id,
  contact: {
    name: newCustomer.contactName,
    family_name: newCustomer.contactFamily_name,
    address: newCustomer.contactAddress,
    city: newCustomer.contactCity,
    zip_code: newCustomer.contactZip_code,
    country: newCustomer.contactCountry,
    phone: newCustomer.contactPhone,
    email: newCustomer.contactEmail,
  },
});

export const getRiskPrediction = async (
  accessToken: string,
  dataPoints: _DataPoints,
  doDatapointsGetFail: MessageTypeC,
  setRiskPredictionResult: (r: RiskPredictionResult) => void,
  setIsLoadingRiskPredictionResult: (x: boolean) => void
) => {
  try {
    const { value, result } = await getLDCoderRiskPrediction(accessToken, {
      personal_id: dataPoints.personal_id,
      data: {
        gender: dataPoints.gender,
        age: dataPoints.age,
        height: dataPoints.height,
        weight: dataPoints.weight,
        is_smoker: dataPoints.is_smoker,
        is_family_history: dataPoints.is_family_history,
        FG_ngDL: dataPoints.FG_ngDL,
        hba1baseline: dataPoints.hba1baseline,
        FST_ng_mL: dataPoints.FST_ng_mL,
        C_pep_ng_mL: dataPoints.C_pep_ng_mL,
      },
    });
    setRiskPredictionResult({ value: parseFloat(value), result });
  } catch (e) {
    doDatapointsGetFail();
  } finally {
    setIsLoadingRiskPredictionResult(false);
  }
};

export const deleteCustomer = async (
  accessToken: string,
  customerId: string,
  customerName: string,
  msgFn: ActionResult
) => {
  const { failMsg, successMsg } = msgFn(customerName);
  try {
    const response = await deleteLDCoderCustomer(accessToken, customerId);
    if (response.status !== 204) {
      return failMsg();
    }
    successMsg();
    return true;
  } catch (e) {
    failMsg();
    return false;
  }
};

export const putCustomer = async (
  accessToken: string,
  customerId: string,
  newCustomer: typeof _newCustomer,
  msgFn: ActionResult
) => {
  const { failMsg, successMsg } = msgFn(newCustomer.name);
  try {
    const newCustomerFormatted = fmtCustomerPut(newCustomer);
    const response = await putLDCoderCustomer(
      accessToken,
      customerId,
      newCustomerFormatted
    );
    if (response.error) {
      return failMsg();
    }
    return successMsg();
  } catch (e) {
    return failMsg();
  }
};

export const postCustomer = async (
  accessToken: string,
  newCustomer: typeof _newCustomer,
  msgFn: ActionResult,
  dispatch: (value: DispatchUpdate<string> | DispatchReset) => void
) => {
  const { failMsg, successMsg } = msgFn(newCustomer.name);
  try {
    const newCustomerFormatted = {
      name: newCustomer.name,
      country_organization_id: newCustomer.country_organization_id,
      contact: {
        name: newCustomer.contactName,
        family_name: newCustomer.contactFamily_name,
        address: newCustomer.contactAddress,
        city: newCustomer.contactCity,
        zip_code: newCustomer.contactZip_code,
        country: newCustomer.contactCountry,
        phone: newCustomer.contactPhone,
        email: newCustomer.contactEmail,
      },
    };
    const response = await postLDCoderCustomer(
      accessToken,
      newCustomerFormatted
    );
    if (response.error) {
      return failMsg();
    }
    successMsg();
    return dispatch({ type: FORM_RESET });
  } catch (e) {
    return failMsg();
  }
};

export const postCustomerAdmin = async (
  accessToken: string,
  newCustomerAdmin: NewCustomerAdmin & { customer_id: string },
  msgFn: ActionResult,
  dispatch: (value: DispatchUpdate<string> | DispatchReset) => void
) => {
  const { failMsg, successMsg } = msgFn(
    `${newCustomerAdmin.name} ${newCustomerAdmin.family_name}`
  );
  try {
    const response = await postLDCoderCustomerAdmin(
      accessToken,
      newCustomerAdmin
    );
    if (response.error) {
      return failMsg();
    }
    successMsg();
    return dispatch({ type: FORM_RESET });
  } catch (e) {
    return failMsg();
  }
};

export const putUser = async (
  accessToken: string,
  accountUser: _Account,
  user: User,
  msgFn: ActionResult,
  setUser: (u: User) => void
) => {
  const { failMsg, successMsg } = msgFn(
    `${accountUser.name} ${accountUser.family_name}`
  );
  let fieldsToUpdate: Partial<User>;
  if (accountUser.password1) {
    fieldsToUpdate = { password: accountUser.password1 };
  } else {
    fieldsToUpdate = {
      name: accountUser.name,
      family_name: accountUser.family_name,
      email: accountUser.email,
    };
    setUser({ ...user, ...fieldsToUpdate });
  }
  try {
    const response = await putLDCoderUser(
      accessToken,
      accountUser.user_id.split('|')[1],
      fieldsToUpdate
    );
    successMsg();
    return response;
  } catch (e) {
    failMsg();
  }
};

export const postUser = async (
  accessToken: string,
  newUser: NewUser & {
    role: string;
  },
  msgFn: ActionResult,
  dispatch: (value: DispatchUpdate<string | boolean> | DispatchReset) => void
) => {
  const { failMsg, successMsg } = msgFn(
    `${newUser.name} ${newUser.family_name}`
  );
  try {
    const response = await postLDCoderUser(accessToken, newUser);
    if (response.error) {
      return failMsg();
    }
    dispatch({ type: FORM_RESET });
    return successMsg();
  } catch (e) {
    return failMsg();
  }
};

export const deleteUser = async (
  accessToken: string,
  user: User,
  setIsDeleteDisabled: (x: boolean) => void,
  msgFn: ActionResult
) => {
  const { failMsg, successMsg } = msgFn(`${user.name} ${user.family_name}`);
  try {
    const response = await deleteLDCoderUser(
      accessToken,
      user.user_id.split('|')[1]
    );
    if (response.status !== 204) {
      return failMsg();
    }
    setIsDeleteDisabled(true);
    successMsg();
    return true;
  } catch (e) {
    failMsg();
    return false;
  }
};
