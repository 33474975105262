import { SelectBoolean, InputField } from '@components';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import { update as _update, Dispatch } from '@utils/helpers-reducers';
import { DataPoints } from '@typings';
import { Validator } from '@validators';

type Props = {
  dataPoints: DataPoints & { personal_id: string };
  dataPointsValidators: Validator;
  dispatch: Dispatch<string | boolean>;
};
export default function RiskPredictionForm({
  dispatch,
  dataPoints,
  dataPointsValidators,
}: Props) {
  const { t } = useTranslation('translation', { i18n });
  const update = _update(dispatch);

  return (
    <>
      <InputField
        validator={dataPointsValidators.personal_id}
        label={t('Form.Field.PersonalID')}
        value={dataPoints.personal_id}
        update={update('personal_id')}
      />
      <SelectBoolean
        label={t('Form.Field.Gender')}
        value={dataPoints.gender}
        update={update('gender')}
        altValues={{ yes: 'SelectBoolean.Female', no: 'SelectBoolean.Male' }}
      />
      <InputField
        validator={dataPointsValidators.age}
        label={t('Form.Field.Age.Label')}
        value={dataPoints.age}
        update={update('age')}
        unit={t('Form.Field.Age.Unit')}
      />
      <InputField
        validator={dataPointsValidators.height}
        label={t('Form.Field.Height.Label')}
        value={dataPoints.height}
        update={update('height')}
        unit={t('Form.Field.Height.Unit')}
      />
      <InputField
        validator={dataPointsValidators.weight}
        label={t('Form.Field.Weight.Label')}
        value={dataPoints.weight}
        update={update('weight')}
        unit={t('Form.Field.Weight.Unit')}
      />
      <SelectBoolean
        label={t('Form.Field.IsSmoker')}
        value={dataPoints.is_smoker}
        update={update('is_smoker')}
      />
      <SelectBoolean
        label={t('Form.Field.IsFamilyHistory')}
        value={dataPoints.is_family_history}
        update={update('is_family_history')}
      />
      <InputField
        validator={dataPointsValidators.FG_ngDL}
        label={t('Form.Field.FG.Label')}
        value={dataPoints.FG_ngDL}
        update={update('FG_ngDL')}
        unit={t('Form.Field.FG.Unit')}
      />
      <InputField
        validator={dataPointsValidators.hba1baseline}
        label={t('Form.Field.HbA1c.Label')}
        value={dataPoints.hba1baseline}
        update={update('hba1baseline')}
        unit={t('Form.Field.HbA1c.Unit')}
      />
      <InputField
        validator={dataPointsValidators.FST_ng_mL}
        label={t('Form.Field.FST.Label')}
        value={dataPoints.FST_ng_mL}
        update={update('FST_ng_mL')}
        unit={t('Form.Field.FST.Unit')}
      />
      <InputField
        validator={dataPointsValidators.C_pep_ng_mL}
        label={t('Form.Field.CPep.Label')}
        value={dataPoints.C_pep_ng_mL}
        update={update('C_pep_ng_mL')}
        unit={t('Form.Field.CPep.Unit')}
      />
    </>
  );
}
