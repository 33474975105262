import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';

import { MessageContext, Message, nullMessage } from '@contexts';
import { useInitAuth0, useIsUserActive } from '@hooks';
import {
  InitProtectedApp,
  InitUnprotectedApp,
  DialogBox,
  Loading,
} from '@components';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const { user: auth0User, isAuthenticated, isLoading, logout } = useAuth0();
  const isUserActive = useIsUserActive();
  const { user, accessToken, setUser, setAccessToken } = useInitAuth0(
    auth0User?.sub ?? null
  );
  const [message, setMessage] = useState<Message>(nullMessage);

  useEffect(() => {
    if (!isUserActive) {
      logout({ returnTo: window.location.origin });
    }
  }, [isUserActive, logout]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {user?.user_metadata.role && isAuthenticated && accessToken ? (
        <MessageContext.Provider value={{ message, setMessage }}>
          <InitProtectedApp
            user={user}
            setUser={setUser}
            accessToken={accessToken}
            setAccessToken={setAccessToken}
          />
          {message.isOpen && <DialogBox />}
        </MessageContext.Provider>
      ) : (
        <InitUnprotectedApp />
      )}
    </ThemeProvider>
  );
}
export default App;
