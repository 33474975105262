import { Customer } from '@ldcoder/common';
import {
  createUserValidators,
  createCustomerValidators,
  createCustomerAdminValidators,
  isAllValid,
} from '@validators';
import { _newCustomer } from '../components/SuperadminCreateCustomer';

export const isNewCustomerEveryValid = isAllValid(createCustomerValidators);

export const isNewCustomerAdminEveryValid = isAllValid(
  createCustomerAdminValidators
);

export const fmtCustomer = (customer: Customer) => ({
  ..._newCustomer,
  name: customer.name,
  country_organization_id: customer.country_organization_id,
  contactName: customer.contact.name,
  contactFamily_name: customer.contact.family_name,
  contactAddress: customer.contact.address,
  contactCity: customer.contact.city,
  contactZip_code: customer.contact.zip_code,
  contactCountry: customer.contact.country,
  contactPhone: customer.contact.phone,
  contactEmail: customer.contact.email,
});

export const fmtCustomerPut = (newCustomer: typeof _newCustomer) => ({
  name: newCustomer.name,
  country_organization_id: newCustomer.country_organization_id,
  contact: {
    name: newCustomer.contactName,
    family_name: newCustomer.contactFamily_name,
    address: newCustomer.contactAddress,
    city: newCustomer.contactCity,
    zip_code: newCustomer.contactZip_code,
    country: newCustomer.contactCountry,
    phone: newCustomer.contactPhone,
    email: newCustomer.contactEmail,
  },
});

export const isNewUserEveryValid = isAllValid(createUserValidators);
