import { useContext, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as UserIcon,
} from "@mui/icons-material";

import { UserContext, UserContextType } from "@contexts";
import { MenuItemLink } from "@components";

export default function AppMenu() {
  const { logout } = useAuth0();
  const { user } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const onClickOpenMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);

  const onClickCloseMenu = (s: string) => {
    if (s === "Logout") {
      logout({ returnTo: window.location.origin });
    }
    if (s === "Account") {
      navigate("/account");
    }
    if (s === "App") {
      navigate(-1);
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{ zIndex: 20000 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 900,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {user.user_metadata.role !== "user" && (
              <Box>
                LD<em>coder</em>
              </Box>
            )}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={onClickOpenMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={onClickOpenMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} title={user.name}>
                  <UserIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={onClickCloseMenu}
            >
              <MenuItem key="menu-app" onClick={() => onClickCloseMenu("App")}>
                <MenuItemLink
                  pathName="/"
                  text={
                    user.user_metadata.role === "user"
                      ? "Risk prediction"
                      : "Dashboard"
                  }
                />
              </MenuItem>
              <MenuItem
                key="menu-account"
                onClick={() => onClickCloseMenu("Account")}
              >
                <MenuItemLink pathName="/account" text="Account" />
              </MenuItem>
              <MenuItem
                key="menu-logout"
                onClick={() => onClickCloseMenu("Logout")}
              >
                <MenuItemLink text="Logout" />
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
