import { useState, useEffect, useReducer, useContext } from "react";
import {
  SelectChangeEvent,
  Paper,
  Button,
  InputLabel,
  Box,
  Grid,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import UserIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

import { ActionResult, useMessage, USER_POST } from "@hooks";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";
import { formReducer } from "@reducers";
import { createUserValidators } from "@validators";
import { Loading, InputField } from "@components";
import { postUser } from "@utils/helpers-ldcoder-api";
import { update as _update } from "@utils/helpers-reducers";
import { isNewUserEveryValid } from "@utils/helpers-components";

const selectRole = (r: string) => (
  <MenuItem value={r} key={`${r}-role`}>
    {r}
  </MenuItem>
);

export const _newUser = {
  name: "",
  family_name: "",
  email: "",
};

export default function AdminCreateUser() {
  const { t } = useTranslation("translation", { i18n });
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const [newUser, dispatch] = useReducer(formReducer, _newUser);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [isSubmittable, setIsSubmittable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { doUserPost } = useMessage(USER_POST);
  const update = _update(dispatch);

  const save = async () => {
    setIsLoading(true);
    await postUser(
      accessToken,
      { ...newUser, role: selectedRole },
      doUserPost as ActionResult,
      dispatch
    );
    setSelectedRole("");
    setIsLoading(false);
  };

  const onChange = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRole(e.target.value);
  };

  useEffect(() => {
    setIsSubmittable(isNewUserEveryValid(newUser));
  }, [newUser]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              elevation={4}
              sx={{
                width: "46ch",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    align: "center",
                    marginBottom: "20px",
                  }}
                >
                  <UserIcon sx={{ fontSize: "80px" }} />
                </Avatar>
              </Grid>
              <Box sx={{ m: 2, width: "40ch" }}>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  size="small"
                  sx={{ width: "40ch" }}
                  value={selectedRole}
                  onChange={onChange}
                  label="Customer"
                >
                  {["user", "admin"].map(selectRole)}
                </Select>
              </Box>
              <InputField
                validator={createUserValidators.name}
                aria-hidden="false"
                aria-label={t("Form.Field.PersonName")}
                label={t("Form.Field.PersonName")}
                sx={{ m: 2, width: "40ch" }}
                value={newUser.name}
                update={update("name")}
              />
              <InputField
                aria-hidden="false"
                aria-label={t("Form.Field.FamilyName")}
                validator={createUserValidators.name}
                sx={{ m: 2, width: "40ch" }}
                label={t("Form.Field.FamilyName")}
                value={newUser.family_name}
                update={update("family_name")}
              />
              <InputField
                aria-hidden="false"
                aria-label={t("Form.Field.Email")}
                validator={createUserValidators.email}
                sx={{ m: 2, width: "40ch" }}
                type="email"
                label={t("Form.Field.Email")}
                value={newUser.email}
                update={update("email")}
              />
              <Button
                sx={{ margin: "15px" }}
                variant="outlined"
                disabled={isSubmittable}
                onClick={save}
              >
                {t("Form.Button.CreateUser")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
