import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@authentication/ProtectedRoute';
import { SuperadminDashboard, Account } from '@views';
import {
  Transactions,
  SuperadminTransactions,
  SuperadminUpdateCustomer,
  SuperadminCustomers,
  SuperadminCreateCustomer,
  SuperadminCreateCustomerAdmin,
} from '@components';

const SuperadminRoutes = (
  <SuperadminDashboard>
    <Routes>
      <Route
        index
        element={<ProtectedRoute component={SuperadminCustomers} />}
      />
      <Route
        path="customers/:customerId"
        element={<ProtectedRoute component={SuperadminUpdateCustomer} />}
      />
      <Route
        path="create-customer"
        element={<ProtectedRoute component={SuperadminCreateCustomer} />}
      />
      <Route
        path="create-customer-admin"
        element={<ProtectedRoute component={SuperadminCreateCustomerAdmin} />}
      />
      <Route
        path="transactions"
        element={<ProtectedRoute component={SuperadminTransactions} />}
      />
      <Route
        path="transactions/:customerId"
        element={<ProtectedRoute component={Transactions} />}
      />
      <Route path="account" element={<ProtectedRoute component={Account} />} />
    </Routes>
  </SuperadminDashboard>
);
export default SuperadminRoutes;
