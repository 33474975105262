import { ReactNode, Fragment } from "react";
import { Box, Drawer, Grid, List, Divider } from "@mui/material";

import { DashboardMenuItem } from "@typings";
import { AppMenu, MenuChoice } from "@components";

type Props = {
  defaultState: string;
  menuItems: DashboardMenuItem[][];
  children: ReactNode | ReactNode[];
};
export default function Dashboard({ menuItems, children }: Props) {
  return (
    <div>
      <AppMenu />
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Box>
            <Drawer variant="permanent" anchor="left">
              <Box sx={{ paddingTop: "100px" }}>
                {menuItems.map((menuGroup: DashboardMenuItem[], i: number) => (
                  <Fragment key={`menu-group-${i}`}>
                    <List>
                      {menuGroup.map((menuItem, i) => (
                        <MenuChoice
                          key={`MenuChoice-${i}`}
                          menuItem={menuItem}
                        />
                      ))}
                    </List>
                    <Divider />
                  </Fragment>
                ))}
              </Box>
            </Drawer>
          </Box>
        </Grid>
        <Box>
          <Grid sx={{ paddingTop: "100px" }}>{children}</Grid>
        </Box>
      </Grid>
    </div>
  );
}
