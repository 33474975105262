import { ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@mui/material';

import { TableItem } from '../hooks/useTable';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
}

type Props = {
  rows: TableItem[];
  columns: Column[];
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (e: unknown, p: number) => void;
  onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement>) => void;
  rowsPerPageOptions: number[];
  onClick: (item: TableItem) => void;
  filter?: boolean;
};
export default function DataTable({
  rows,
  columns,
  filter,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  onClick,
  rowsPerPageOptions,
}: Props) {
  const rs = filter
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows;

  const handleOnClick = (item: TableItem) => onClick(item);

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 600, width: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={`column-${column.id}`}
                  align="right"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rs.length &&
              rs.map((row, i) => {
                return (
                  <TableRow
                    key={`row-${i}`}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => handleOnClick(row)}
                  >
                    {columns.map((column) => (
                      <TableCell key={`row-${column.id}`} align="right">
                        {row[column.id] as string}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
