import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { DashboardMenuItem } from '@typings';

type Props = {
  menuItem: DashboardMenuItem;
};
export default function MenuChoice({ menuItem }: Props) {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  return (
    <Link key={`menu-item-${menuItem.name}`} to={menuItem.dashboardAreaLink}>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <menuItem.icon />
          </ListItemIcon>
          <ListItemText
            primary={menuItem.name}
            sx={{
              color: path === menuItem.dashboardAreaLink ? '#90caf9' : '#fff',
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
