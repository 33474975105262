import { useState, useEffect, useReducer, useContext } from "react";
import {
  Paper,
  SelectChangeEvent,
  Button,
  Box,
  Divider,
  Typography,
  Grid,
  Avatar,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

import { Customer } from "@ldcoder/common";
import {
  CUSTOMERS,
  ActionResult,
  useMessage,
  MessageTypeC,
  SOMETHING_WENT_WRONG,
  NO_SELECTED_CUSTOMER,
  useLDCoderApi,
  CUSTOMER_ADMIN_POST,
} from "@hooks";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";
import { formReducer } from "@reducers";
import { createCustomerAdminValidators } from "@validators";
import { isNewCustomerAdminEveryValid } from "@utils/helpers-components";
import { Loading, InputField } from "@components";
import { postCustomerAdmin } from "@utils/helpers-ldcoder-api";
import { update as _update } from "@utils/helpers-reducers";

const _newCustomerAdmin = {
  name: "",
  family_name: "",
  email: "",
};

const selectCustomer = (c: Customer) => (
  <MenuItem value={c.name} key={`customer-admin-for-${c.name}`}>
    {c.name}
  </MenuItem>
);

export default function SuperadminCreateCustomerAdmin() {
  const { t } = useTranslation("translation", { i18n });
  const { doCustomerAdminPost, doNoSelectedCustomer, doSomethingWentWrong } =
    useMessage(NO_SELECTED_CUSTOMER, SOMETHING_WENT_WRONG, CUSTOMER_ADMIN_POST);
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const [isLoading, setIsLoading] = useState(false);
  const { data, error } = useLDCoderApi<Customer[]>(accessToken, CUSTOMERS);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [newCustomerAdmin, dispatch] = useReducer(
    formReducer,
    _newCustomerAdmin
  );
  const [isSubmittable, setIsSubmittable] = useState(true);
  const update = _update(dispatch);

  const save = async () => {
    if (!selectedCustomer.length) {
      (doNoSelectedCustomer as MessageTypeC)();
    }
    if (data) {
      const customerId = data.find(
        (c: Customer) => c.name === selectedCustomer
      )!.customer_id;
      setIsLoading(true);
      await postCustomerAdmin(
        accessToken,
        { ...newCustomerAdmin, customer_id: customerId },
        doCustomerAdminPost as ActionResult,
        dispatch
      );
      setIsLoading(false);
    }
  };

  const onChange = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCustomer(e.target.value);
  };

  useEffect(() => {
    const isSubmittable =
      (selectedCustomer.length > 0 &&
        isNewCustomerAdminEveryValid(newCustomerAdmin)) ??
      false;
    setIsSubmittable(isSubmittable);
  }, [setIsSubmittable, newCustomerAdmin, selectedCustomer]);

  useEffect(() => {
    if (data && data.length) {
      setSelectedCustomer(data[0].name);
      //setCustomers(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  if (error) {
    (doSomethingWentWrong as MessageTypeC)();
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          width: "53ch",
          padding: "10px",
          marginTop: "10px",
          marginLeft: "100px",
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Avatar
            sx={{
              width: 100,
              height: 100,
              align: "center",
              marginBottom: "20px",
            }}
          >
            <AddBusinessIcon sx={{ fontSize: "80px" }} />
          </Avatar>
        </Grid>
        <Box sx={{ width: "50ch" }}>
          <Typography variant="h6" sx={{ margin: "10px 12px" }}>
            {t("Form.Header.Administrator")}
          </Typography>
          <Box sx={{ margin: "15px" }}>
            <InputLabel id="customers-select-label">Customer</InputLabel>
            <Select
              labelId="customers-select-label"
              id="demo-simple-select"
              size="small"
              sx={{ width: "40ch" }}
              value={selectedCustomer}
              onChange={onChange}
              label="Customer"
            >
              {data && data.map(selectCustomer)}
            </Select>
          </Box>
          <InputField
            validator={createCustomerAdminValidators.name}
            label={t("Form.Field.PersonName")}
            sx={{ m: 2, width: "40ch" }}
            value={newCustomerAdmin.name}
            update={update("name")}
          />
          <InputField
            validator={createCustomerAdminValidators.family_name}
            label={t("Form.Field.FamilyName")}
            sx={{ m: 2, width: "40ch" }}
            value={newCustomerAdmin.family_name}
            update={update("family_name")}
          />
          <InputField
            validator={createCustomerAdminValidators.email}
            label={t("Form.Field.AdministratorEmail")}
            sx={{ m: 2, width: "40ch" }}
            value={newCustomerAdmin.email}
            update={update("email")}
          />
          <Divider />
          <Button
            sx={{ margin: "15px" }}
            variant="outlined"
            disabled={isSubmittable}
            onClick={save}
          >
            {t("SuperadminCreateCustomerAdmin.Button.CreateNewAdministrator")}
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
}
