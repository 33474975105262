import { Stack, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  text?: string;
};
export default function Loading({ text }: Props) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      {text ? (
        <Grid container justifyContent="center">
          <Stack direction="column">
            <Grid container justifyContent="center" sx={{ margin: '10px' }}>
              <Typography>{text}</Typography>
            </Grid>
            <Grid container justifyContent="center" sx={{ margin: '10px' }}>
              <CircularProgress color="inherit" />
            </Grid>
          </Stack>
        </Grid>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Backdrop>
  );
}
