import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Skeleton,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { Customer, Transaction } from "@ldcoder/common";
import {
  useMessage,
  MessageTypeC,
  SOMETHING_WENT_WRONG,
  TRANSACTIONS_BY_CUSTOMER_ID,
  useLDCoderApi,
  useTable,
} from "@hooks";
import {
  AccessTokenContext,
  AccessTokenContextType,
  UserContext,
  UserContextType,
} from "@contexts";
import saveDatasetToExcel from "@utils/save-dataset-to-excel";
import { DataTable } from "@components";
import {
  convertToUnixTimestamp,
  getOneMonthBack,
  getYearMonthDay,
} from "@utils/date-fns";

type Props = {
  header?: string;
};
export default function Transactions({ header }: Props) {
  const location = useLocation();
  const { doSomethingWentWrong } = useMessage(SOMETHING_WENT_WRONG);
  const { t } = useTranslation("translation", { i18n });
  const { user } = useContext(UserContext) as UserContextType;
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const { rows, total, page, perPage, updateTable } = useTable();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(getOneMonthBack(new Date()))
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const { isLoading, data, error } = useLDCoderApi<Transaction[]>(
    accessToken,
    TRANSACTIONS_BY_CUSTOMER_ID,
    {
      customerId:
        (location?.state as Customer)?.customer_id ??
        user.user_metadata.customer_id,
      startDate: convertToUnixTimestamp(startDate),
      endDate: convertToUnixTimestamp(endDate),
    }
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    updateTable({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateTable({
      perPage: +event.target.value,
      page: 0,
    });
  };

  const saveToExcel = async () =>
    rows && (await saveDatasetToExcel(rows, "Transactions"));

  useEffect(() => {
    if (data && data.length) {
      const ts = data.map((t: Transaction) => ({
        full_name: t.full_name ?? t.created_by,
        created_at: getYearMonthDay(t.created_at),
        from_ip: t.from_ip,
      }));
      console.log(ts);
      updateTable({ rows: ts, total: data.length });
    } else {
      updateTable({ rows: [], total: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, startDate, endDate, error]);

  if (error) {
    (doSomethingWentWrong as MessageTypeC)();
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container>
            <Paper
              elevation={3}
              sx={{
                width: "90%",
                padding: "20px",
                marginTop: "10px",
                marginLeft: "300px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "30px" }}>
                {header ?? "Transactions"}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ marginBottom: "10px" }}>
                  <DatePicker
                    disableFuture={true}
                    label={t("Form.Field.DatePicker.StartDate")}
                    value={startDate}
                    onChange={setStartDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    disableFuture={true}
                    label={t("Form.Field.DatePicker.EndDate")}
                    value={endDate}
                    onChange={setEndDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
              {!isLoading && rows ? (
                <>
                  <DataTable
                    rows={rows}
                    columns={[
                      {
                        id: "created_at",
                        label: t("Form.Field.DataTable.CreatedAt"),
                        minWidth: 200,
                      },
                      {
                        id: "full_name",
                        label: t("Form.Field.DataTable.CreatedBy"),
                        minWidth: 200,
                      },
                      {
                        id: "from_ip",
                        label: t("Form.Field.DataTable.FromIP"),
                        minWidth: 200,
                      },
                    ]}
                    page={page}
                    count={total}
                    rowsPerPage={perPage}
                    onClick={console.log}
                    rowsPerPageOptions={[10, 20, 50]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    filter={true}
                  />
                  <Button
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                    onClick={saveToExcel}
                  >
                    {t("Transactions.Button.Export")}
                  </Button>
                </>
              ) : (
                <Box sx={{ pt: 0.5 }}>
                  {[
                    "70%",
                    "60%",
                    "90%",
                    "80%",
                    "70%",
                    "40%",
                    "50%",
                    "70%",
                    "50%",
                    "40%",
                  ].map((width: string, i: number) => (
                    <Skeleton width={width} key={`skel-${i}`} />
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
