type DA<T> = (DispatchUpdate<T> & DispatchReset) | DispatchUpdate<T>;

export const FORM_RESET = 'FORM_RESET';

export const FORM_UPDATE = 'FORM_UPDATE';

export type DispatchUpdate<T> = {
  type: string;
  payload: {
    key: string;
    value: T;
  };
};

export type DispatchReset = { type: typeof FORM_RESET };

export function fmtDispatch<T>(key: string, value: T) {
  return {
    type: FORM_UPDATE,
    payload: { key, value },
  };
}

export const isDispatchUpdate = <T>(
  x: DispatchUpdate<T> | DispatchReset
): x is DispatchUpdate<T> => 'payload' in x;

export type Dispatch<T> = (v: DA<T>) => void;

export const update =
  <T>(dispatch: Dispatch<T>) =>
  (key: string) =>
  (value: T) => {
    dispatch(fmtDispatch(key, value));
  };
