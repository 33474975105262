import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

type Props = {
  label: string;
  value: boolean | null;
  update: (v: boolean) => void;
  sx?: { m?: number; width?: string; marginLeft?: string };
  altValues?: {
    yes: string;
    no: string;
  };
};

export default function SelectBoolean({
  label,
  sx,
  value,
  update,
  altValues,
}: Props) {
  const { t } = useTranslation('translation', { i18n });
  const labelId = `${label.replace(' ', '-')}-label`;
  const id = label.replace(' ', '-');
  let v = '';
  if (!(value === null)) {
    v = value ? 'yes' : 'no';
  }

  const onChange = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    e.stopPropagation();
    return update(e.target.value === 'yes');
  };

  return (
    <FormControl
      sx={sx ? sx : { m: 1, width: '25ch' }}
      size="small"
      variant="outlined"
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={v}
        label={label}
        onChange={onChange}
      >
        <MenuItem value={'yes'}>
          {altValues?.yes ? t(altValues.yes) : t('SelectBoolean.Yes')}
        </MenuItem>
        <MenuItem value={'no'}>
          {altValues?.no ? t(altValues.no) : t('SelectBoolean.No')}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
