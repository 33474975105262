import { User } from '@typings';

export async function getAuth0User(
  accessToken: string,
  sub: string,
  domain: string
): Promise<User | null> {
  try {
    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${sub}`;
    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const userWithMetaData = await metadataResponse.json();
    return userWithMetaData;
  } catch (e) {
    throw e;
  }
}
