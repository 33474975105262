import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const {
  REACT_APP_AUDIENCE: AUDIENCE,
  REACT_APP_SCOPE: SCOPE,
  REACT_APP_DOMAIN: DOMAIN,
  REACT_APP_CLIENT_ID: CLIENT_ID,
} = process.env;

const Auth0ProviderWithHistory: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const history = useNavigate();

  const onRedirectCallback = () => {
    history(window.location.pathname);
  };

  return (
    <Auth0Provider
      redirectUri={window.location.origin}
      audience={AUDIENCE}
      scope={SCOPE}
      domain={DOMAIN ?? ''}
      clientId={CLIENT_ID ?? ''}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
