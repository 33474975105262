import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

import { Paragraph } from '@components';
import DashboardPaper from './DashboardPaper';

export default function RiskPredictionTechnialExplanation() {
  const { t } = useTranslation('translation', { i18n });

  return (
    <DashboardPaper>
      <Paragraph>
        <strong>{t('RiskPrediction.Information.IntendedUse.Title')}:</strong>{' '}
        {t('RiskPrediction.Information.IntendedUse.Body')}
      </Paragraph>
      <Paragraph>
        <strong>
          {t('RiskPrediction.Information.ResultInterpretation.Title')}:
        </strong>{' '}
        {t('RiskPrediction.Information.ResultInterpretation.Body')}
      </Paragraph>
      <Paragraph>
        <strong>
          {t('RiskPrediction.Information.Recommendations.Title')}:
        </strong>{' '}
        {t('RiskPrediction.Information.Recommendations.Body')}
      </Paragraph>
      <Paragraph>
        <strong>{t('RiskPrediction.Information.Performance.Title')}:</strong>{' '}
        {t('RiskPrediction.Information.Performance.Body')}
      </Paragraph>
      <Paragraph>
        <strong>{t('RiskPrediction.Information.TestRanges.Title')}:</strong>{' '}
        {t('RiskPrediction.Information.TestRanges.Body')}
      </Paragraph>
    </DashboardPaper>
  );
}
