import { useState, useContext, useReducer } from 'react';
import { Paper, Button, Box, Grid, Avatar } from '@mui/material';
import UserIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

import { Loading, InputField } from '@components';
import { ActionResult, useMessage, USER_PUT } from '@hooks';
import { formReducer } from '@reducers';
import {
  AccessTokenContext,
  AccessTokenContextType,
  UserContext,
  UserContextType,
} from '@contexts';
import { putUser } from '@utils/helpers-ldcoder-api';
import { update as _update } from '@utils/helpers-reducers';
import { User } from '@typings';
import { accountValidators } from '@validators';

export type _Account = User & {
  password1: string;
  password2: string;
  isSetPassword: boolean;
};

export default function Account() {
  const { t } = useTranslation('translation', { i18n });
  const { doUserPut } = useMessage(USER_PUT);
  const { user, setUser } = useContext(UserContext) as UserContextType;
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const [isLoading, setIsLoading] = useState(false);
  const [accountUser, dispatch] = useReducer(formReducer, {
    ...(user as User),
    password1: '',
    password2: '',
    isSetPassword: false,
  });

  const update = _update(dispatch);

  const onClickChangePassword = () =>
    update('isSetPassword')(!accountUser.isSetPassword);

  const onClickUpdatePassword = async () => {
    update('isSetPassword')(!accountUser.isSetPassword);
    const result = await save();
    if (result) {
      update('password1')('');
      update('password2')('');
    }
  };

  const save = async () => {
    setIsLoading(true);
    const result = await putUser(
      accessToken,
      accountUser,
      user,
      doUserPut as ActionResult,
      setUser
    );
    setIsLoading(false);
    return result;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ marginTop: '100px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              elevation={3}
              sx={{
                width: '46ch',
                padding: '10px',
                marginTop: '10px',
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    align: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <UserIcon sx={{ fontSize: '80px' }} />
                </Avatar>
              </Grid>
              <InputField
                validator={accountValidators.name}
                label={t('Form.Field.PersonName')}
                sx={{ m: 2, width: '40ch' }}
                value={accountUser.name}
                update={update('name')}
              />
              <InputField
                validator={accountValidators.name}
                sx={{ m: 2, width: '40ch' }}
                label={t('Form.Field.FamilyName')}
                value={accountUser.family_name}
                update={update('family_name')}
              />
              <InputField
                validator={accountValidators.email}
                sx={{ m: 2, width: '40ch' }}
                type="email"
                label={t('Form.Field.Email')}
                value={accountUser.email}
                update={update('email')}
              />
              <Button sx={{ margin: '15px' }} variant="outlined" onClick={save}>
                {t('Account.Button.SaveUser')}
              </Button>
              <Box>
                <Button
                  sx={{ margin: '15px' }}
                  variant="outlined"
                  onClick={onClickChangePassword}
                >
                  {t('Account.Button.ChangePassword')}
                </Button>
                {accountUser.isSetPassword && (
                  <>
                    <InputField
                      validator={accountValidators.password}
                      sx={{ m: 2, width: '40ch' }}
                      label={t('Form.Field.Password')}
                      type="password"
                      value={accountUser.password1}
                      update={update('password1')}
                    />
                    <InputField
                      validator={accountValidators.password}
                      sx={{ m: 2, width: '40ch' }}
                      type="password"
                      label={t('Form.Field.Password')}
                      value={accountUser.password2}
                      update={update('password2')}
                    />
                    <Button
                      sx={{ margin: '15px' }}
                      variant="outlined"
                      disabled={
                        !(
                          accountUser.password1.length > 8 &&
                          accountUser.password1 === accountUser.password2
                        )
                      }
                      onClick={onClickUpdatePassword}
                    >
                      {t('Account.Button.UpdatePassword')}
                    </Button>
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
