import { useAuth0 } from '@auth0/auth0-react';
import { Grid, CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

const buttonStyle = {
  border: 'none',
  padding: '15px 32px',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '18px',
  margin: '4px 2px',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'black',
  width: '250px',
  borderRadius: '30px',
};

export default function LoginButton() {
  const { t } = useTranslation('translation', { i18n });
  const { loginWithRedirect } = useAuth0();

  return (
    <Grid container justifyContent="center">
      <CssBaseline />
      <button onClick={loginWithRedirect} style={{ ...buttonStyle }}>
        {t('LoginButton.Login').toUpperCase()}
      </button>
    </Grid>
  );
}
