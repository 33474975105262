import { ChangeEvent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { Customer } from "@ldcoder/common";
import {
  CUSTOMERS,
  useLDCoderApi,
  SOMETHING_WENT_WRONG,
  MessageTypeC,
  useMessage,
  useTable,
} from "@hooks";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";
import { DataTable, Loading } from "@components";

export default function SuperadminCustomers() {
  const { doSomethingWentWrong } = useMessage(SOMETHING_WENT_WRONG);
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const { rows, total, page, perPage, updateTable } = useTable();
  const { t } = useTranslation("translation", { i18n });
  const navigate = useNavigate();
  const { isLoading, data, error } = useLDCoderApi<Customer[]>(
    accessToken,
    CUSTOMERS
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    updateTable({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateTable({
      perPage: +event.target.value,
      page: 0,
    });
  };

  const isCustomer = <T,>(x: T | Customer): x is Customer => "customer_id" in x;

  const handleOnCustomerClick = <T,>(c: T) => {
    if (isCustomer(c)) {
      navigate(`/customers/${c.customer_id}`, { state: c });
    }
  };

  useEffect(() => {
    if (data && data.length) {
      updateTable({ rows: data, total: data.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, error]);

  if (error) {
    (doSomethingWentWrong as MessageTypeC)();
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: "20px",
              marginTop: "10px",
              marginLeft: "100px",
            }}
          >
            {rows && rows.length && (
              <DataTable
                rows={rows}
                columns={[
                  {
                    id: "name",
                    label: t("Form.Field.DataTable.Name"),
                    minWidth: 200,
                  },
                ]}
                onClick={handleOnCustomerClick}
                page={page}
                count={total}
                rowsPerPage={perPage}
                rowsPerPageOptions={[10, 20, 50]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
