import request from './fetch';
import {
  NewCustomer,
  NewCustomerAdmin,
  NewUser,
  PersonDataPoints,
  User,
} from '@typings';

const { REACT_APP_BASE_URL: BASE_URL } = process.env;

export type Params = {
  page?: number;
  perPage?: number;
  customerId?: string;
  startDate?: number;
  endDate?: number;
};

const createCRUDHeader = (accessToken: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
});

export const getLDCoderRiskPrediction = (
  accessToken: string,
  dataPoints: PersonDataPoints
) =>
  request('POST', `${BASE_URL}/data-points`)(createCRUDHeader(accessToken))(
    dataPoints
  );

export const putLDCoderUser = (
  accessToken: string,
  userId: string,
  userFieldsToUpdate: Partial<User>
) =>
  request('PUT', `${BASE_URL}/users/${userId}`)(createCRUDHeader(accessToken))(
    userFieldsToUpdate
  );

export const deleteLDCoderUser = (accessToken: string, userId: string) =>
  request(
    'DELETE',
    `${BASE_URL}/users/${userId}`
  )(createCRUDHeader(accessToken))();

export const deleteLDCoderCustomer = (
  accessToken: string,
  customerId: string
) =>
  request(
    'DELETE',
    `${BASE_URL}/customers/${customerId}`
  )(createCRUDHeader(accessToken))();

export const postLDCoderUser = (accessToken: string, user: NewUser) =>
  request('POST', `${BASE_URL}/users`)(createCRUDHeader(accessToken))(user);

export const postLDCoderCustomer = (
  accessToken: string,
  newCustomer: NewCustomer
) =>
  request('POST', `${BASE_URL}/customers`)(createCRUDHeader(accessToken))(
    newCustomer
  );

export const postLDCoderCustomerAdmin = (
  accessToken: string,
  newCustomerAdmin: NewCustomerAdmin
) =>
  request('POST', `${BASE_URL}/customers-admin`)(createCRUDHeader(accessToken))(
    newCustomerAdmin
  );

export const putLDCoderCustomer = (
  accessToken: string,
  customerId: string,
  updatedCustomer: NewCustomer
) =>
  request(
    'PUT',
    `${BASE_URL}/customers/${customerId}`
  )(createCRUDHeader(accessToken))(updatedCustomer);

export const getCustomers = (accessToken: string, _params: Params) =>
  request('GET', `${BASE_URL}/customers`)(createCRUDHeader(accessToken))();

export const getUsersByCustomerId = (accessToken: string, params: Params) =>
  request(
    'GET',
    `${BASE_URL}/users?page=${params.page}&per_page=${params.perPage}`
  )(createCRUDHeader(accessToken))();

export const getTransactionsByCustomerId = (
  accessToken: string,
  params: Params
) =>
  request(
    'GET',
    `${BASE_URL}/transactions?customer_id=${params.customerId}&start_date=${params.startDate}&end_date=${params.endDate}`
  )(createCRUDHeader(accessToken))();
