import { Stack, Typography } from "@mui/material";

import { AddressField, AddressFieldItem } from "@components";
import ce from "@assets/ce.png";
import factory from "@assets/factory.png";

export default function RiskPredictionLundochLDCoderInformation() {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={{ padding: "20px" }}
    >
      <Stack direction="row">
        <AddressFieldItem sx={{ width: "50px" }}>
          <img src={factory} alt="Factory" style={{ width: "35px" }} />
        </AddressFieldItem>
        <AddressFieldItem>
          <AddressField>Lundoch Diagnostics AB</AddressField>
          <AddressField>Medeon Science park</AddressField>
          <AddressField>SE-205 12 Malmö</AddressField>
          <AddressField>Sweden</AddressField>
        </AddressFieldItem>
      </Stack>
      <AddressFieldItem>
        <img
          src={ce}
          alt="CE marking"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            marginTop: "8px",
          }}
        />
        <Typography variant="h4" sx={{ marginTop: "50px" }}>
          Version 1.0.1
        </Typography>
      </AddressFieldItem>
    </Stack>
  );
}
