import React, { useContext, useEffect } from "react";
import { Skeleton, Box, Typography, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { User } from "@typings";
import {
  USERS_GET_BY_CUSTOMER_ID,
  useLDCoderApi,
  useTable,
  SOMETHING_WENT_WRONG,
  useMessage,
  MessageTypeC,
} from "@hooks";
import { TableItem } from "../hooks/useTable";
import { DataTable } from "@components";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";

export default function AdminUsers() {
  const { t } = useTranslation("translation", { i18n });
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const { doSomethingWentWrong } = useMessage(SOMETHING_WENT_WRONG);
  const { rows, total, page, perPage, updateTable } = useTable();
  const { isLoading, data, error } = useLDCoderApi<{
    users: User[];
    total: number;
  }>(accessToken, USERS_GET_BY_CUSTOMER_ID, { page, perPage });
  const navigate = useNavigate();

  const handleChangePage = (_event: unknown, newPage: number) =>
    updateTable({ page: newPage });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateTable({
      perPage: +event.target.value,
      page: 0,
    });
  };

  const isUser = <T,>(x: T | User): x is User => "user_id" in x;

  const handleUserClick = <T,>(u: T) => {
    if (isUser(u)) {
      navigate(`/users/${u.user_id}`, { state: u });
    }
  };

  useEffect(() => {
    if (data && data.users.length) {
      const rs: TableItem[] = data.users.map((u: User) => ({
        ...u,
        ...u?.user_metadata,
      }));
      updateTable({
        rows: rs,
        total: data.total,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, error]);

  if (error) {
    (doSomethingWentWrong as MessageTypeC)();
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              elevation={3}
              sx={{
                width: "73ch",
                padding: "20px",
                marginTop: "10px",
                marginLeft: "200px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "30px" }}>
                Users
              </Typography>
              {rows && rows.length ? (
                <DataTable
                  rows={rows}
                  columns={[
                    {
                      id: "name",
                      label: t("Form.Field.DataTable.Name"),
                      minWidth: 200,
                    },
                    {
                      id: "email",
                      label: t("Form.Field.DataTable.Email"),
                      minWidth: 200,
                    },
                    {
                      id: "role",
                      label: t("Form.Field.DataTable.Role"),
                      minWidth: 200,
                    },
                  ]}
                  page={page}
                  count={total}
                  onClick={handleUserClick}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[10, 20, 50]}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton width="70%" />
                  <Skeleton width="60%" />
                  <Skeleton width="90%" />
                  <Skeleton width="80%" />
                  <Skeleton />
                  <Skeleton width="40%" />
                  <Skeleton width="50%" />
                  <Skeleton width="70%" />
                  <Skeleton />
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
