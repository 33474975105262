import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n/i18n';

export default function AppHeader() {
  const { t } = useTranslation('translation', { i18n });

  return (
    <Typography
      align="center"
      color="primary"
      sx={{
        color: 'white',
        fontSize: '5rem',
        weight: '900',
        marginBottom: '1rem',
      }}
    >
      {t('AppIntroTitle.LDcoderPart1')}
      <em>{t('AppIntroTitle.LDcoderPart2')}</em>
    </Typography>
  );
}
