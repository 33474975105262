import { ComponentType } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { AppMenu, Loading } from "@components";

type Props = {
  component: ComponentType;
};

export default function ProtectedRoute({ component }: Props) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
}
