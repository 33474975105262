import { useState, useContext, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Avatar,
  Paper,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { Customer } from "@ldcoder/common";
import { AccessTokenContext, AccessTokenContextType } from "@contexts";
import { update as _update } from "@utils/helpers-reducers";
import { fmtCustomer } from "@utils/helpers-components";
import { isAllValid, createCustomerValidators } from "@validators";
import { formReducer } from "@reducers";
import {
  useMessage,
  ActionResult,
  INVALID_FIELDS,
  CUSTOMER_DELETE_VERIFY,
  CUSTOMER_DELETE,
  CUSTOMER_PUT,
  MessageTypeA,
  MessageTypeC,
} from "@hooks";
import { deleteCustomer, putCustomer } from "@utils/helpers-ldcoder-api";
import { Loading, InputField } from "@components";

const isNewCustomerEveryValid = isAllValid(createCustomerValidators);

export default function SuperadminUpdateCustomer() {
  const location = useLocation();
  const { t } = useTranslation("translation", { i18n });
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const {
    doInvalidFields,
    doCustomerDelete,
    doCustomerDeleteVerify,
    doCustomerPut,
  } = useMessage(
    INVALID_FIELDS,
    CUSTOMER_DELETE,
    CUSTOMER_DELETE_VERIFY,
    CUSTOMER_PUT
  );
  const customer = location.state as Customer;
  const customerToUpdateFmt = fmtCustomer(customer);
  const [newCustomer, dispatch] = useReducer(formReducer, customerToUpdateFmt);
  const navigate = useNavigate();
  const update = _update(dispatch);

  const save = async () => {
    if (!isNewCustomerEveryValid(newCustomer)) {
      setIsLoading(true);
      await putCustomer(
        accessToken,
        customer.customer_id,
        newCustomer,
        doCustomerPut as ActionResult
      );
      setIsLoading(false);
    } else {
      (doInvalidFields as MessageTypeC)();
    }
  };

  const _deleteCustomer = async () => {
    setIsLoading(true);
    const isSuccess = await deleteCustomer(
      accessToken,
      customer.customer_id,
      customer.name,
      doCustomerDelete as ActionResult
    );
    setIsLoading(false);
    if (isSuccess) {
      navigate(-1);
    }
  };

  const onClickDeleteCustomer = () =>
    (doCustomerDeleteVerify as MessageTypeA)(customer.name, _deleteCustomer);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              elevation={3}
              sx={{
                width: "53ch",
                padding: "20px",
                marginTop: "10px",
                marginLeft: "100px",
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    align: "center",
                    marginBottom: "20px",
                  }}
                >
                  <AddBusinessIcon sx={{ fontSize: "80px" }} />
                </Avatar>
              </Grid>
              <Box sx={{ width: "50ch" }}>
                <Typography variant="h4" sx={{ margin: "30px 12px" }}>
                  Update customer
                </Typography>
                <InputField
                  validator={createCustomerValidators.name}
                  label={t("Form.Field.CompanyName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.name}
                  update={update("name")}
                />
                <InputField
                  validator={createCustomerValidators.country_organization_id}
                  label={t("Form.Field.CountryOrganizationId")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.country_organization_id}
                  update={update("country_organization_id")}
                />
                <Divider />
                <Typography variant="h6" sx={{ margin: "10px 12px" }}>
                  Contact
                </Typography>
                <InputField
                  validator={createCustomerValidators.contactName}
                  label={t("Form.Field.PersonName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactName}
                  update={update("contactName")}
                />
                <InputField
                  validator={createCustomerValidators.contactFamily_name}
                  label={t("Form.Field.FamilyName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactFamily_name}
                  update={update("contactFamily_name")}
                />
                <InputField
                  validator={createCustomerValidators.contactAddress}
                  label={t("Form.Field.Address")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactAddress}
                  update={update("contactAddress")}
                />
                <InputField
                  validator={createCustomerValidators.contactCity}
                  label={t("Form.Field.City")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactCity}
                  update={update("contactCity")}
                />
                <InputField
                  validator={createCustomerValidators.contactZip_code}
                  label={t("Form.Field.ZipCode")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactZip_code}
                  update={update("contactZip_code")}
                />
                <InputField
                  validator={createCustomerValidators.contactCountry}
                  label={t("Form.Field.Country")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactCountry}
                  update={update("contactCountry")}
                />
                <InputField
                  validator={createCustomerValidators.contactPhone}
                  label={t("Form.Field.Phone")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactPhone}
                  update={update("contactPhone")}
                />
                <InputField
                  validator={createCustomerValidators.contactEmail}
                  label={t("Form.Field.Email")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactEmail}
                  update={update("contactEmail")}
                />
                <Divider />
              </Box>
              <Box>
                <Button
                  sx={{ margin: "15px" }}
                  variant="outlined"
                  onClick={save}
                >
                  {t("UpdateCustomer.Button.UpdateCustomer")}
                </Button>
                <Button
                  sx={{ margin: "15px", float: "right" }}
                  variant="outlined"
                  onClick={onClickDeleteCustomer}
                >
                  {t("UpdateCustomer.Button.DeleteCustomer")}
                </Button>
                <Divider />

                <Button
                  sx={{ margin: "15px" }}
                  variant="outlined"
                  onClick={() => navigate("/")}
                >
                  {t("UpdateCustomer.Button.BackToCustomers")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
