import { createContext } from 'react';

export type Agree = () => void;

export type Message = {
  isOpen: boolean;
  title: string;
  body: string;
  severity: string;
  onAgree: Agree | null;
  onDisagree?: boolean | null;
};

export type SetMessage = (m: Message) => void;

export type MessageContextType = {
  message: Message;
  setMessage: SetMessage;
};

export const nullMessage = {
  isOpen: false,
  title: '',
  body: '',
  severity: '',
  onAgree: null,
  onDisagree: null,
};

export const MessageContext = createContext<MessageContextType>({
  message: nullMessage,
  setMessage: (m: Message) => {},
});
