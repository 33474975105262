import { useCallback, useState, useEffect } from 'react';

import {
  getTransactionsByCustomerId,
  getUsersByCustomerId,
  getCustomers,
  Params,
} from '@utils/ldcoder-api';

export const USERS_GET_BY_CUSTOMER_ID = 'USERS_GET_BY_CUSTOMER_ID';

export const TRANSACTIONS_BY_CUSTOMER_ID = 'TRANSACTIONS_BY_CUSTOMER_ID';

export const CUSTOMERS = 'CUSTOMERS';

const api = new Map()
  .set(USERS_GET_BY_CUSTOMER_ID, getUsersByCustomerId)
  .set(TRANSACTIONS_BY_CUSTOMER_ID, getTransactionsByCustomerId)
  .set(CUSTOMERS, getCustomers);

export function useLDCoderApi<T>(
  accessToken: string,
  requestType: string,
  params?: Params
) {
  const [data, setData] = useState<null | T>(null);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const [error, setError] = useState<null | string>(null);

  const request = api.get(requestType);

  const call = useCallback(async () => {
    if (!api.has(requestType)) {
      throw new Error('Invalid requiest type.');
    }
    setIsLoading(true);
    request(accessToken, params)
      .then(setData)
      .catch(setError)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params?.page,
    params?.perPage,
    params?.customerId,
    params?.endDate,
    params?.startDate,
  ]);

  useEffect(() => {
    call();
  }, [call]);

  return { data, isLoading, error };
}
