const padStartWithZero = (x: number) => x.toString().padStart(2, '0');

export function getOneMonthBack(d: Date) {
  d.setMonth(d.getMonth() - 1);
  return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
    .map(padStartWithZero)
    .join('-');
}

export function convertToUnixTimestamp(date: Date | null) {
  return date ? date.getTime() : 0;
}

export function getYearMonthDay(timeStamp: string) {
  const d = new Date(parseInt(timeStamp, 10));
  return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
    .map(padStartWithZero)
    .join('-');
}
