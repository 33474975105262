import { isName, isEmail } from './validator-helpers';

export const createUserValidators = {
  name: {
    validate: isName,
    error: 'Validator.Name',
  },
  email: {
    validate: isEmail,
    error: 'Validator.Email',
  },
};
