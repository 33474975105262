import { isName, isEmail, isValidPassword } from './validator-helpers';

export const accountValidators = {
  name: {
    validate: isName,
    error: 'Validator.Name',
  },
  email: {
    validate: isEmail,
    error: 'Validator.Email',
  },
  password: {
    validate: isValidPassword,
    error: 'Validator.Password',
  },
};
