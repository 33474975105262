import * as Excel from 'exceljs/dist/exceljs.bare';
import file from 'file-saver';

function getColumns(dataPoints: Record<string, unknown>): Excel.Column[] {
  const columns = Object.keys(dataPoints).map((entry) => ({
    header: entry,
    key: entry,
    width: 20,
  })) as Excel.Column[];
  return columns;
}

function getRows(dataPoints: Record<string, unknown>): Record<string, string> {
  const rows = Object.entries(dataPoints).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: String(v) }),
    {} as Record<string, string>
  );
  return rows;
}

export default async function saveDataToXLS(
  set: Record<string, unknown> | Record<string, unknown>[],
  name: string
) {
  const wb = new Excel.Workbook();
  if (!wb.addWorksheet) {
    return;
  }
  const ws = wb.addWorksheet('0');
  const isList = Array.isArray(set);
  const columns = getColumns(isList ? set[0] : set);
  const rows = isList ? set.map(getRows) : [getRows(set)];
  ws.columns = columns;
  ws.getRow(1).font = { bold: true } as Excel.Font;
  ws.addRows(rows);
  const buffer = await wb?.xlsx?.writeBuffer();
  if (buffer) file.saveAs(new Blob([buffer]), `${name}.xlsx`);
}
