import {
  isAge,
  isPersonalId,
  isHeight,
  isWeight,
  isFG_ngDL,
  isFST_ng_mL,
  isC_pep_ng_mL,
  isHba1Baseline,
} from './validator-helpers';

export const dataPointsValidators = {
  personal_id: {
    validate: isPersonalId,
    error: 'Validator.PersonalID',
  },
  age: {
    validate: isAge,
    error: 'Validator.Age',
  },
  height: {
    validate: isHeight,
    error: 'Validator.Height',
  },
  weight: {
    validate: isWeight,
    error: 'Validator.Weight',
  },
  FG_ngDL: {
    validate: isFG_ngDL,
    error: 'Validator.FG_ngDL',
  },
  hba1baseline: {
    validate: isHba1Baseline,
    error: 'Validator.hba1baseline',
  },
  FST_ng_mL: {
    validate: isFST_ng_mL,
    error: 'Validator.FST_ng_mL',
  },
  C_pep_ng_mL: {
    validate: isC_pep_ng_mL,
    error: 'Validator.C_pep_ng_mL',
  },
};
