import { ChangeEvent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

import i18n from "../i18n/i18n";
import { Customer } from "@ldcoder/common";
import {
  CUSTOMERS,
  useLDCoderApi,
  useTable,
  SOMETHING_WENT_WRONG,
  MessageTypeC,
  useMessage,
} from "@hooks";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";
import { TableItem } from "../hooks/useTable";
import { DataTable, Loading } from "@components";

export default function SuperadminTransactions() {
  const navigate = useNavigate();
  const { doSomethingWentWrong } = useMessage(SOMETHING_WENT_WRONG);
  const { t } = useTranslation("translation", { i18n });
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const { rows, total, page, perPage, updateTable } = useTable();
  const { isLoading, data, error } = useLDCoderApi<Customer[]>(
    accessToken,
    CUSTOMERS,
    { page, perPage }
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    updateTable({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    updateTable({
      perPage: +event.target.value,
      page: 0,
    });
  };

  const onClick = (item: TableItem) => {
    if ("customer_id" in item) {
      navigate(`/transactions/${item.customer_id}`, { state: item });
    }
  };

  useEffect(() => {
    if (data && data.length) {
      updateTable({ rows: data, total: data.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, error]);

  if (error) {
    (doSomethingWentWrong as MessageTypeC)();
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid>
            <Paper
              elevation={3}
              sx={{
                width: "100ch",
                padding: "20px",
                marginTop: "10px",
                marginLeft: "300px",
              }}
            >
              {rows && rows.length && (
                <DataTable
                  rows={rows}
                  columns={[
                    {
                      id: "name",
                      label: t("Form.Field.DataTable.Name"),
                      minWidth: 200,
                    },
                  ]}
                  onClick={onClick}
                  page={page}
                  count={total}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[10, 20, 50]}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
