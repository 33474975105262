import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const AddressField = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: '1.3rem',
  fontWeight: 500,
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
export default AddressField;
