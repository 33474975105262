import {
  isName,
  isOrganizationId,
  isCity,
  isCountry,
  isAddress,
  isPhone,
  isZipCode,
  isEmail,
} from './validator-helpers';

export const createCustomerValidators = {
  name: {
    validate: isName,
    error: 'Validator.Name',
  },
  country_organization_id: {
    validate: isOrganizationId,
    error: 'Validator.OrganizationID',
  },
  contactName: {
    validate: isName,
    error: 'Validator.Name',
  },
  contactFamily_name: {
    validate: isName,
    error: 'Validator.Name',
  },
  contactAddress: {
    validate: isAddress,
    error: 'Validator.Address',
  },
  contactCity: {
    validate: isCity,
    error: 'Validator.City',
  },
  contactZip_code: {
    validate: isZipCode,
    error: 'Validator.ZipCode',
  },
  contactCountry: {
    validate: isCountry,
    error: 'Validator.Country',
  },
  contactPhone: {
    validate: isPhone,
    error: 'Validator.Phone',
  },
  contactEmail: {
    validate: isEmail,
    error: 'Validator.Email',
  },
};
