import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { User } from '@typings';
import { getAuth0User } from '@utils/auth0';
import { useMessage, USER_GET_FAIL, MessageTypeC } from '@hooks';

export default function useInitAuth0(sub: null | string) {
  const { doUserGetFail } = useMessage(USER_GET_FAIL);
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [user, setUser] = useState<null | User>(null);
  const [accessToken, setAccessToken] = useState<null | string>(null);

  useEffect(() => {
    (async () => {
      if (!sub) {
        return { user, accessToken };
      }
      const domain = 'ldcoder.eu.auth0.com';
      const scope = 'read:current_user';
      try {
        const id_token = await getIdTokenClaims({
          audience: `https://${domain}/api/v2/`,
          scope,
        });
        if (id_token && '__raw' in id_token) {
          setAccessToken(id_token.__raw);
        }
        const auth0AccessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope,
        });
        const fetchedUser = await getAuth0User(auth0AccessToken, sub, domain);
        setUser(fetchedUser);
      } catch (e) {
        (doUserGetFail as MessageTypeC)();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub]);

  return { user, accessToken, setUser, setAccessToken };
}
