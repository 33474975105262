import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import {
  Button,
  Box,
  Backdrop,
  Dialog,
  DialogActions,
  AlertColor,
  DialogContent,
  DialogContentText,
  Alert,
  DialogTitle,
} from '@mui/material';
import { MessageContext, nullMessage } from '@contexts';

export default function DialogBox() {
  const { t } = useTranslation('translation', { i18n });
  const { message, setMessage } = useContext(MessageContext);
  const { isOpen, title, body, severity, onAgree, onDisagree } = message;

  const close = () => setMessage(nullMessage);

  const clickOnAgree = () => {
    if (onAgree) {
      onAgree();
      close();
    }
  };

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Alert severity={severity as AlertColor}>{body}</Alert>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={clickOnAgree} autoFocus>
            {t('DialogBox.Button.Ok')}
          </Button>
          {onDisagree && (
            <Button onClick={close} autoFocus>
              {t('DialogBox.Button.Cancel')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
}
