import { useState, useEffect, useReducer, useContext } from "react";
import {
  Paper,
  Button,
  Box,
  Divider,
  Typography,
  Grid,
  Avatar,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

import { ActionResult, useMessage, CUSTOMER_POST } from "@hooks";
import { AccessTokenContextType, AccessTokenContext } from "@contexts";
//import { _newCustomer, createCustomerReducer } from '@reducers';
import { formReducer } from "@reducers";
import { createCustomerValidators } from "@validators";

import { isNewCustomerEveryValid } from "@utils/helpers-components";
import { InputField, Loading } from "@components";
import { postCustomer } from "@utils/helpers-ldcoder-api";
import { update as _update } from "@utils/helpers-reducers";

export const _newCustomer = {
  name: "",
  country_organization_id: "",
  contactName: "",
  contactFamily_name: "",
  contactAddress: "",
  contactCity: "",
  contactZip_code: "",
  contactCountry: "",
  contactPhone: "",
  contactEmail: "",
};

export default function SuperadminCreateCustomer() {
  const { t } = useTranslation("translation", { i18n });
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const [newCustomer, dispatch] = useReducer(formReducer, _newCustomer);
  const [isSubmittable, setIsSubmittable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { doCustomerPost } = useMessage(CUSTOMER_POST);
  const update = _update(dispatch);

  const save = async () => {
    setIsLoading(true);
    await postCustomer(
      accessToken,
      newCustomer,
      doCustomerPost as ActionResult,
      dispatch
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setIsSubmittable(isNewCustomerEveryValid(newCustomer));
  }, [setIsSubmittable, newCustomer]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              elevation={3}
              sx={{
                width: "53ch",
                padding: "10px",
                marginTop: "10px",
                marginLeft: "100px",
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                <Avatar
                  sx={{
                    width: 100,
                    height: 100,
                    align: "center",
                    marginBottom: "20px",
                  }}
                >
                  <AddBusinessIcon sx={{ fontSize: "80px" }} />
                </Avatar>
              </Grid>
              <Box sx={{ width: "50ch" }}>
                <Typography variant="h4" sx={{ margin: "30px 12px" }}>
                  {t("Form.Header.CreateCustomer")}
                </Typography>
                <InputField
                  validator={createCustomerValidators.name}
                  label={t("Form.Field.CompanyName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.name}
                  update={update("name")}
                />
                <InputField
                  validator={createCustomerValidators.country_organization_id}
                  label={t("Form.Field.CountryOrganizationId")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.country_organization_id}
                  update={update("country_organization_id")}
                />
                <Divider />
                <Typography variant="h6" sx={{ margin: "10px 12px" }}>
                  {t("Form.Header.Contact")}
                </Typography>

                <InputField
                  validator={createCustomerValidators.contactName}
                  label={t("Form.Field.PersonName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactName}
                  update={update("contactName")}
                />
                <InputField
                  validator={createCustomerValidators.contactFamily_name}
                  label={t("Form.Field.FamilyName")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactFamily_name}
                  update={update("contactFamily_name")}
                />
                <InputField
                  validator={createCustomerValidators.contactAddress}
                  label={t("Form.Field.Address")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactAddress}
                  update={update("contactAddress")}
                />
                <InputField
                  validator={createCustomerValidators.contactCity}
                  label={t("Form.Field.City")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactCity}
                  update={update("contactCity")}
                />
                <InputField
                  validator={createCustomerValidators.contactZip_code}
                  label={t("Form.Field.ZipCode")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactZip_code}
                  update={update("contactZip_code")}
                />
                <InputField
                  validator={createCustomerValidators.contactCountry}
                  label={t("Form.Field.Country")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactCountry}
                  update={update("contactCountry")}
                />
                <InputField
                  validator={createCustomerValidators.contactPhone}
                  label={t("Form.Field.Phone")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactPhone}
                  update={update("contactPhone")}
                />
                <InputField
                  validator={createCustomerValidators.contactEmail}
                  label={t("Form.Field.Email")}
                  sx={{ m: 2, width: "40ch" }}
                  value={newCustomer.contactEmail}
                  update={update("contactEmail")}
                />
                <Divider />
                <Button
                  sx={{ margin: "15px" }}
                  variant="outlined"
                  disabled={isSubmittable}
                  onClick={save}
                >
                  {t("Form.Button.CreateCustomer")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
