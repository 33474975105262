import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@authentication/ProtectedRoute';
import { AdminDashboard, Account } from '@views';
import {
  AdminUsers,
  AdminCreateUser,
  AdminUpdateUser,
  Transactions,
} from '@components';

const AdminRoutes = (
  <AdminDashboard>
    <Routes>
      <Route index element={<ProtectedRoute component={AdminUsers} />} />
      <Route
        path="users/:userId"
        element={<ProtectedRoute component={AdminUpdateUser} />}
      />
      <Route
        path="create-user"
        element={<ProtectedRoute component={AdminCreateUser} />}
      />
      <Route
        path="transactions"
        element={<ProtectedRoute component={Transactions} />}
      />
      <Route path="account" element={<ProtectedRoute component={Account} />} />
    </Routes>
  </AdminDashboard>
);
export default AdminRoutes;
