import { ReactNode } from 'react';
import {
  AccountCircleOutlined,
  GroupOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import { Dashboard } from '@components';

type Props = {
  children: ReactNode | ReactNode[];
};
export default function AdminDashboard({ children }: Props) {
  const { t } = useTranslation('translation', { i18n });

  return (
    <Dashboard
      defaultState="/"
      menuItems={[
        [
          {
            name: t('DashboardMenu.Admin.Users'),
            dashboardAreaLink: '/',
            icon: GroupOutlined,
          },
          {
            name: t('DashboardMenu.Admin.CreateUser'),
            dashboardAreaLink: '/create-user',
            icon: AccountCircleOutlined,
          },
        ],
        [
          {
            name: t('DashboardMenu.Admin.Transactions'),
            dashboardAreaLink: '/transactions',
            icon: ReceiptLongOutlined,
          },
        ],
      ]}
    >
      {children}
    </Dashboard>
  );
}
