import { Box, Stack, Grid } from '@mui/material';

import { AppHeader, LoginButton } from '@components';
import ldcoderIntroImage from '@assets/ldcoder-image.jpg';

export default function LoginScreen() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Stack direction="row">
        <Stack direction="column" sx={{ padding: '50px' }}>
          <AppHeader />
          <LoginButton />
        </Stack>
        <Box
          sx={{
            width: '50%',
            display: { xs: 'none', md: 'block', lg: 'block' },
          }}
        >
          <img src={ldcoderIntroImage} alt="LDcoder" style={{ width: '90%' }} />
        </Box>
      </Stack>
    </Grid>
  );
}
