import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "@authentication/ProtectedRoute";
import { RiskPrediction, Account } from "@views";
import { AppMenu } from "@components";

const UserRoutes = (
  <>
    <AppMenu />
    <Routes>
      <Route index element={<ProtectedRoute component={RiskPrediction} />} />
      <Route path="account" element={<ProtectedRoute component={Account} />} />
    </Routes>
  </>
);
export default UserRoutes;
