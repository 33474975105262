export const en = {
  translation: {
    AppIntroTitle: {
      LDcoderPart1: 'LD',
      LDcoderPart2: 'coder',
    },
    Loading: {
      PredictionResult:
        'Running data points through the LDcoder prediction model... Please wait.',
    },
    DashboardMenu: {
      Admin: {
        Users: 'Users',
        CreateUser: 'Create user',
        Transactions: 'Transactions',
      },
      SuperAdmin: {
        Customers: 'Customers',
        CreateCustomer: 'Create customer',
        CreateCustomerAdmin: 'Create customer admin',
        Transactions: 'Transactions',
      },
    },
    DashboardTable: {
      RowsPerPage: 'Rows per page',
    },
    DatePicker: {
      LabelStartDate: 'Start date',
      LabelEndDate: 'End date',
    },
    Form: {
      Header: {
        CreateCustomer: 'Create customer',
        Contact: 'Contact',
        Administrator: 'Administrator',
      },
      Field: {
        PersonName: 'First name',
        Gender: 'Gender',
        FamilyName: 'Family name',
        Email: 'Email',
        CompanyName: 'Company name',
        CountryOrganizationId: 'Country organization id',
        Address: 'Address',
        City: 'City',
        ZipCode: 'Zip code',
        DataTable: {
          Name: 'Name',
          Role: 'Role',
          Email: 'Email',
          CustomerID: 'Customer id',
          CreatedAt: 'Created at',
          CreatedBy: 'Created by',
          FromIP: 'From ip',
        },
        DatePicker: {
          StartDate: 'Start date',
          EndDate: 'End date',
        },
        IsSmoker: 'Is smoker',
        IsFamilyHistory: 'Family history',
        Country: 'Country',
        Phone: 'Phone',
        PersonalID: 'Personal id',
        Age: {
          Label: 'Age',
          Unit: 'years',
        },
        Height: {
          Label: 'Height',
          Unit: 'cm',
        },
        Weight: {
          Label: 'Weight',
          Unit: 'kg',
        },
        FG: {
          Label: 'FG',
          Unit: 'mg/dL',
        },
        HbA1c: {
          Label: 'HbA1c',
          Unit: '%',
        },
        FST: {
          Label: 'FST',
          Unit: 'pg/mL',
        },
        CPep: {
          Label: 'C-pep',
          Unit: 'ng/mL',
        },
        Password: 'Password',
        ContactEmail: 'Contact email',
        AdministratorEmail: 'Administrator email',
      },
      Button: {
        CreateUser: 'Create user',
        CreateCustomer: 'Create customer',
        Reset: 'Reset',
      },
    },
    RiskPrediction: {
      Header: 'RiskPrediction',
      Result: 'Type 2 diabetes risk level is: ',
      ResultLabel: 'Result',
      Information: {
        IntendedUse: {
          Title: 'Intended use',
          Body: 'LDcoder is to be used by laboratory technicians and health care providers, to analyze blood sample in any adult individual (>18 years old), to identify the risk of developing type 2 diabetes within 4 years, in a laboratory office setting, using a standard computer.',
        },
        ResultInterpretation: {
          Title: 'Result interpretation',
          Body: 'The risk of the individual is identified and displayed by in the “Type 2 Diabetes Risk Level” chart.',
        },
        Recommendations: {
          Title: 'Recommendations',
          Body: 'LDcoder does not provide a disease diagnostic determination. Repeat LDcoder test every 6 months, and/or use alternative testing method(s) and clinical findings before a diagnostic determination is made.',
        },
        Performance: {
          Title: 'Performance',
          Body: 'Accuracy 85.1% (specificity 0.8; sensitivity 0.72)',
        },
        TestRanges: {
          Title: 'Test ranges',
          Body: '0<HbA1c<100 (%), 0<follistatin<50000 (pg/mL) and 0<C-peptide<1000 (ng/mL)',
        },
      },
      Button: {
        Export: 'Export',
        Analyze: 'Analyze',
        Reset: 'Reset',
      },
    },
    UpdateUser: {
      Button: {
        DeleteUser: 'Delete user',
        BackToUsers: 'Back to users',
      },
      Info: {
        Email: 'Email',
        Role: 'Role',
        LastLogin: 'Last login',
        LastIP: 'Last ip',
      },
    },
    UpdateCustomer: {
      Button: {
        UpdateCustomer: 'Save',
        DeleteCustomer: 'Delete customer',
        BackToCustomers: 'Back to customers',
      },
    },
    Transactions: {
      Button: {
        Export: 'export',
      },
    },
    SuperadminCreateCustomerAdmin: {
      Button: {
        CreateNewAdministrator: 'Create new administrator',
      },
    },
    Account: {
      Hover: 'Open settings',
      Logout: 'Logout',
      Account: 'Account',
      Dashboard: 'Dashboard',
      Button: {
        UpdatePassword: 'Update password',
        ChangePassword: 'Change password',
        SaveUser: 'Save user',
      },
    },
    DialogBox: {
      Button: {
        Ok: 'Ok',
        Cancel: 'Cancel',
      },
    },
    SelectBoolean: {
      Yes: 'Yes',
      No: 'No',
      Female: 'Female',
      Male: 'Male',
    },
    RiskPredictionResultVisualisation: {
      ResultText: 'Type 2 diabetes risk level is',
    },
    PageNotFound: {
      MessagePart1: 'Page Not Found - redirecting in',
      MessagePart2: 'seconds',
    },
    LoginButton: { Login: 'Sign in' },
    DataModal: { Close: 'Close' },
    Validator: {
      Name: 'Name must have at least one character.',
      Email: 'Invalid email',
      Password: 'Invalid format for password',
      OrganizationID: '',
      Address: '',
      City: '',
      ZipCode: '',
      Country: '',
      Phone: '',
      PersonalID: 'Only 12 digits allowed.',
      Age: 'Only numbers. Age >= 18 years is allowed.',
      Height: 'Only numbers. 50-300 cm.',
      Weight: 'Only numbers. Minimum 40kg.',
      FG_ngDL: 'Only numbers. >0 and <1000.',
      hba1baseline: 'Only numbers. >0 and <100',
      FST_ng_mL: 'Only numbers. >0 and <50000',
      C_pep_ng_mL: 'Only numbers. >0 and <1000',
    },
    Messages: {
      CustomerDeleteVerify: {
        Title: 'Are you sure?',
        Body: (name: string) => `Do you want to delete ${name}?`,
      },
      UserDeleteVerify: {
        Title: 'Are you sure?',
        Body: (name: string) => `Do you want to delete ${name}?`,
      },
      DataPointsGetFail: {
        Title: 'Something went wrong.',
        Body: 'Unable to fetch result.',
      },
      DataPointsResetVerify: {
        Title: 'Are you sure?',
        Body: 'All data will be lost.',
      },
      DataPointsVerify: {
        Title: 'Has patient given consent?',
        Body: '...REPLACE ME WITH JURIDICAL BOILERPLATE...',
      },
      InvalidFields: {
        Title: 'Something went wrong.',
        Body: 'Invalid fields.',
      },
      UnableToFetchUser: {
        Title: 'Something went wrong.',
        Body: 'Unable to fetch user.',
      },
      NoSelectedCustomer: {
        Title: 'Something went wrong.',
        Body: 'No selected customer.',
      },
      SomethingWentWrong: {
        Title: 'Something went wrong.',
        Body: '',
      },
      Action: {
        Title: (title: string) => title,
        Body: (name: string, actionType: string) => `${name} was ${actionType}`,
      },
      Fail: 'Fail',
      Success: 'Success',
      NotCreated: 'not created',
      Created: 'created',
      NotDeleted: 'not deleted',
      Deleted: 'deleted',
      NotUpdated: 'not updated',
      Updated: 'updated',
    },
  },
};
