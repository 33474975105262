import { useState, useEffect, useContext, useReducer } from 'react';
import { Button, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
/* @ts-ignore */
import './RiskPrediction.css';

import { AccessTokenContext, AccessTokenContextType } from '@contexts';
import { getLDCoderRiskPrediction } from '@utils/ldcoder-api';
import { FORM_RESET } from '@utils/helpers-reducers';
import { formReducer } from '@reducers';
import { dataPointsValidators, isAllValid } from '@validators';
import saveDatasetToExcel from '@utils/save-dataset-to-excel';
import lundochLogo from '@assets/lundoch.png';
import {
  RiskPredictionTechnicalExplanation,
  RiskPredictionLundochLDCoderInformation,
  RiskPredictionResultVisualisation,
  RiskPredictionForm,
  Loading,
} from '@components';
import {
  DATAPOINTS_RESET_VERIFY,
  DATAPOINTS_GET_FAIL,
  DATAPOINTS_VERIFY,
  MessageTypeD,
  MessageTypeC,
  useMessage,
} from '@hooks';
import DashboardPaper from '@components/DashboardPaper';

export const _dataPoints = {
  personal_id: '',
  gender: null,
  age: '',
  height: '',
  weight: '',
  is_smoker: null,
  FG_ngDL: '',
  hba1baseline: '',
  FST_ng_mL: '',
  C_pep_ng_mL: '',
  is_family_history: null,
};

export type _DataPoints = typeof _dataPoints;

const getDate = () => new Date().toISOString().split('T')[0];

const isDataPointsEveryValid = isAllValid(dataPointsValidators);

export type RiskPredictionResult = {
  value: number;
  result: string;
};

export default function RiskPrediction() {
  const { t } = useTranslation('translation', { i18n });
  const { doDatapointsGetFail, doDatapointsResetVerify, doDatapointsVerify } =
    useMessage(DATAPOINTS_GET_FAIL, DATAPOINTS_RESET_VERIFY, DATAPOINTS_VERIFY);
  const { accessToken } = useContext(
    AccessTokenContext
  ) as AccessTokenContextType;
  const [dataPoints, dispatch] = useReducer(formReducer, _dataPoints);
  const [isSubmittable, setIsSubmittable] = useState(true);
  const [riskPredictionResult, setRiskPredictionResult] =
    useState<RiskPredictionResult>({ value: 0, result: '' });
  const [isLoadingRiskPredictionResult, setIsLoadingRiskPredictionResult] =
    useState(false);

  useEffect(() => {
    setIsSubmittable(isDataPointsEveryValid(dataPoints));
  }, [dataPoints]);

  const saveToExcel = async () => {
    const fmtDataPoints = Object.entries(dataPoints).reduce((acc, [k, v]) => ({
      ...acc,
      [k.replace('_', ' ')]: v,
    }));
    console.log(dataPoints);
    console.log({ ...dataPoints });
    console.log(fmtDataPoints);
    await saveDatasetToExcel(
      {
        ...dataPoints,
        created: getDate(),
      },
      `risk-prediction-datapoints-for-${dataPoints.personal_id}.xlsx`
    );
  };

  const _submit = () => {
    (doDatapointsVerify as MessageTypeD)(submit)();
  };

  const submit = async () => {
    setIsLoadingRiskPredictionResult(true);
    try {
      const { value, result } = await getLDCoderRiskPrediction(accessToken, {
        personal_id: dataPoints.personal_id,
        data: {
          gender: dataPoints.gender,
          age: dataPoints.age,
          height: dataPoints.height,
          weight: dataPoints.weight,
          is_smoker: dataPoints.is_smoker,
          is_family_history: dataPoints.is_family_history,
          FG_ngDL: dataPoints.FG_ngDL,
          hba1baseline: dataPoints.hba1baseline,
          FST_ng_mL: dataPoints.FST_ng_mL,
          C_pep_ng_mL: dataPoints.C_pep_ng_mL,
        },
      });
      setRiskPredictionResult({ value: parseFloat(value), result });
    } catch (e) {
      (doDatapointsGetFail as MessageTypeC)();
    } finally {
      setIsLoadingRiskPredictionResult(false);
    }
  };

  const reset = () => {
    setRiskPredictionResult({ value: 0, result: '' });
    dispatch({ type: FORM_RESET });
  };

  const _reset = () => {
    (doDatapointsResetVerify as MessageTypeD)(reset)();
  };

  if (isLoadingRiskPredictionResult) {
    return <Loading text={t('Loading.PredictionResult')} />;
  }

  return (
    <div className="predictionGrid">
      <div className="predictionForm">
        <DashboardPaper>
          <Grid item columns={1} md={1}>
            <RiskPredictionForm
              dispatch={dispatch}
              dataPoints={dataPoints}
              dataPointsValidators={dataPointsValidators}
            />
          </Grid>
          <Box sx={{ marginTop: '30px', marginLeft: '7px' }}>
            <Button
              variant="outlined"
              sx={{ marginRight: '10px' }}
              onClick={_reset}
            >
              {t('RiskPrediction.Button.Reset')}
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: '10px' }}
              disabled={!isLoadingRiskPredictionResult && isSubmittable}
              onClick={_submit}
            >
              {t('RiskPrediction.Button.Analyze')}
            </Button>
            <Button
              sx={{ marginRight: '10px' }}
              variant="outlined"
              onClick={saveToExcel}
              disabled={riskPredictionResult.result === ''}
            >
              {t('RiskPrediction.Button.Export')}
            </Button>
          </Box>
        </DashboardPaper>
      </div>
      <div className="predictionVisualisation">
        <RiskPredictionResultVisualisation
          riskPredictionResult={riskPredictionResult}
        />
      </div>
      <div className="predictionExplanation">
        <RiskPredictionTechnicalExplanation />
      </div>
      <div className="predictionInformation">
        <RiskPredictionLundochLDCoderInformation />
      </div>
      <div className="predictionLogo">
        <img
          src={lundochLogo}
          alt="Lundoch logotype"
          style={{ maxWidth: '70%' }}
        />
      </div>
    </div>
  );
}
