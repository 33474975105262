import { Paper } from "@mui/material";
import { ReactNode } from "react";

type DashboardPaperProps = {
  children: ReactNode;
};

export default function DashboardPaper({ children }: DashboardPaperProps) {
  return (
    <Paper elevation={4} sx={{ height: "100%", padding: "20px" }}>
      {children}
    </Paper>
  );
}
